import React from 'react';
import Image from "react-image-enlarger";


export function ZoomableImg({ src }) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <div style={{ padding: "5px", overflow:'hidden', maxHeight: '600px'}}>
      <Image
        style={{  height: "auto", overflow: 'hidden',borderRadius:'40px' }}
        zoomed={zoomed}
        src={src}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
    </div>
  );
}