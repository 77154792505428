// libraries
import React from 'react';

// assets
import miragif4 from '../../assets/mira_rectangle.JPG';
import miracircle from '../../assets/mira_circle1.png';

// css
import './AboutMe.scss';
import './AboutMeMobile.scss';
import HeaderBar from '../../Components/HeaderBar';
import { transform } from 'lodash';
import Footer from '../ProjectDetailPage/ProjectDetailPageComponents/Footer';
import { checkMobile } from '../../Styles/ScreenCalcs';

class AboutMe extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMobile: checkMobile() }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.setState({ isMobile: checkMobile() });
  }

  render() {
    if(this.state.isMobile) return this.mobileRender()
    else return this.normalRender()

  }

  mobileRender() {
    return (
      <div>
        <HeaderBar/>
        <div className='about-me-page-layout-mobile'>
          <img style={{width:"25vw"}} src={miracircle}/>
          <h1 style={{ marginTop:"10px"}}>Mira Ram</h1>
          <div className='about-me-rectangle-mobile'/>
          <p><a href="mailto:mira.ram@gmail.com" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>mira.ram(@)gmail(.)com</a></p>
          <p><a href="https://www.linkedin.com/in/miraram/" target="_blank" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>linkedin.com/in/miraram/</a></p>
          <div className="intro-blurb">
          <h4 className='bullet-blurbs'>Product Designer • XR Researcher • Fun-haver</h4>
                <p>
                Nice to meet you! I'm Mira; a UX designer at <a className="about-link" target="_blank" href="https://www.campfire3d.com/">Campfire</a>    with <b>7+ years</b> of experience designing 0-1 solutions for clients ranging from startups to large corporations. My work has been featured in the <b>
                  <a className="about-link" target="_blank" href="https://www.imdb.com/title/tt24486108/?ref_=ls_t_6">Sundance Film Festival</a>, 
                  <a className="about-link" target="_blank" href="https://abcnews.go.com/Health/young-americans-created-apps-address-mental-health-crisis/story?id=76183076"> ABC News</a>,</b> and 
                  <b><a className="about-link" target="_blank" href="https://worldchannel.org/episode/america-reframed-by-water/"> PBS</a></b>. I specialize in business-focused design strategy rooted in thorough research and brought to life with engaging visual interfaces.
                </p>
                <p>
                I graduated from <b>Dartmouth College</b> with an M.S. in Computer Science w/ Digital Arts concentration in 2022 and a B.A. in Psychology and Digital Arts in 2020. My thesis research was focused on social interaction in VR and published in the Springer series <a className="about-link" target="_blank" href="https://link.springer.com/chapter/10.1007/978-3-031-62281-6_21">"Lecture Notes in Networks and Systems."</a> Shoot me an email if you'd like to chat!
                </p>
                {/* <p>
                When I'm not designing or researching, you can find me taking on all sorts of side quests. I
                </p> */}
        
                <p>Likes: drawing, video games, sleeping, cats, collecting trinkets</p>
                <p>Dislikes: also drawing, celery, when people use icons with inconsistent line weights  </p>


          </div>
        </div>
        <Footer />
      </div>
    )
  }

  normalRender() {
    return (
      <div>
        <HeaderBar/>
        <div className='about-me-page-layout'>
          <div className='about-me-flex-wrapper'>
            <div className='about-me-left'>
              <img style={{width:"18vw", maxWidth:'380px'}} src={miragif4}/>
              <div>
                <h1 style={{marginTop:"10px"}}>Mira Ram</h1>
                <div className='about-me-rectangle'/>
                <p><a href="mailto:mira.ram@gmail.com" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>mira.ram(@)gmail(.)com</a></p>

                <p><a href="https://www.linkedin.com/in/miraram/" target="_blank" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>linkedin.com/in/miraram/</a></p>
              </div>
            </div>
            <div className='about-me-blurb'>
              <div className>
                <h4 className='bullet-blurbs'>Product Designer • XR Researcher • Fun-haver</h4>
                <p>
                Nice to meet you! I'm Mira; a UX designer at <a className="about-link" target="_blank" href="https://www.campfire3d.com/">Campfire</a>    with <b>7+ years</b> of experience designing 0-1 solutions for clients ranging from startups to large corporations. My work has been featured in the <b>
                  <a className="about-link" target="_blank" href="https://www.imdb.com/title/tt24486108/?ref_=ls_t_6">Sundance Film Festival</a>, 
                  <a className="about-link" target="_blank" href="https://abcnews.go.com/Health/young-americans-created-apps-address-mental-health-crisis/story?id=76183076"> ABC News</a>,</b> and 
                  <b><a className="about-link" target="_blank" href="https://worldchannel.org/episode/america-reframed-by-water/"> PBS</a></b>. I specialize in business-focused design strategy rooted in thorough research and brought to life with engaging visual interfaces.
                </p>
                <p>
                I graduated from <b>Dartmouth College</b> with an M.S. in Computer Science w/ Digital Arts concentration in 2022 and a B.A. in Psychology and Digital Arts in 2020. My thesis research was focused on social interaction in VR and published in the Springer series <a className="about-link" target="_blank" href="https://link.springer.com/chapter/10.1007/978-3-031-62281-6_21">"Lecture Notes in Networks and Systems."</a> Shoot me an email if you'd like to chat!
                </p>
                {/* <p>
                When I'm not designing or researching, you can find me taking on all sorts of side quests. I
                </p> */}
        
                <p>Likes: drawing, video games, sleeping, cats, collecting trinkets</p>
                <p>Dislikes: also drawing, celery, when people use icons with inconsistent line weights  </p>

              </div>
            </div>
          </div>
          {this.props.imageDecos}
        </div>
        <Footer />
      </div>
    )
  }
}

export default AboutMe;
