// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ImageScrollList from './ProjectDetailPageComponents/ImageScrollList';

import ScrollButton from '../../Components/ScrollButton';

// assets
// Import your Google case study assets here
// Example:
// import banner from '../../assets/google/google_banner.png';
import floorDwellingMira from '../../assets/landing_page_2/floor_dwelling_mira.png';

// Add imports for case study images/videos
import campfireSample from '../../assets/coverletters/campfire_sample.png'; 
import netxdSample from '../../assets/coverletters/netxd_sample.png'; 
import flourishSample from '../../assets/coverletters/flourish_sample.png'; 
import unmaskedSample from '../../assets/coverletters/unmasked_sample.png'; 

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css';
import '../../Styles/coverletter.scss';
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';

class GoogleCloudAICoverLetterPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    // Replace with your Google banner when available
    // this.background = banner;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#ed8f8a' // Pink from About page links
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div key={index} className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        {/* <div className={'project-detail-page-banner'} style={{backgroundColor: '#F1F1F1', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} /> */}
        <br/><br/><br/><br/><br/>
        <div className='project-detail-page-layout'>
          {/* ---------- Title ---------- */}
          {/* ---------- Password ---------- */}
          
   
          <PasswordComponent
            sha256={'3ce0930d33ddd1af644b7f1e733caf725f1e650b73483d474afa92cd131b1afb'}
            color={'#39271C'}>   
          
            
          <h1 className="h1">Google Cover Letter</h1>
          <h2 className="h2 subtitle">
            Why I'd love to create compelling AI experiences with the Creative Content, Demos & Experiments team
          </h2>
          <br/>

          {/* ---------- About ---------- */}
          <h2 className='h2'>About</h2>
          <p className='p'>
            Thanks for stopping by! I'm Mira, a NYC-based UX designer with <b className="text-emphasis">7+ years of experience</b> designing <b className="text-emphasis">0-to-1 solutions</b> for clients ranging from <b className="text-emphasis">Y Combinator-backed startups to Meta</b>. My work has been featured at <b className="text-emphasis">Sundance Film Festival, ABC News, and PBS</b>. I currently lead the design of enterprise 3D collaboration software at <a href="https://campfire3d.com/" target="_blank" rel="noopener noreferrer" style={{color: this.accent_color, fontWeight: 600}}>Campfire</a>, where I create <b className="text-emphasis">multi-platform, research-driven solutions</b> that balance innovation with usability. I specialize in <b className="text-emphasis">business-focused design strategy</b> rooted in thorough research and brought to life with engaging visual interfaces.
          </p> <br/>
          <div className="center-content">
            <img 
              src={floorDwellingMira} 
              alt="Mira illustration" 
              className="profile-image"
            />
          </div>
          <br/>

          {/* ---------- Why Google? ---------- */}
          <h2 className='h2'>Why Google?</h2>
          <p className='p'>
            <b className="text-heading">Google's Legacy of Innovation</b><br/>
            From entering Google Doodle contests as a kid, I've been inspired by Google's ability to <b className="text-emphasis">merge creativity and technology</b>. This grew into a lifelong appreciation for Google's <b className="text-emphasis">user-first philosophy, experimental mindset, and scale of impact</b> as I pursued design. Drawing from my experience building intuitive multi-platform experiences and implementing research-backed design decisions, I'm eager for the opportunity to contribute to Google's culture of innovation.
          </p>
          
          <p className='p'>
            <b className="text-heading">Why the Creative Content, Demos & Experiments Team?</b><br/>
            I'm drawn to this team because I love the challenge of <b className="text-emphasis">translating complex AI technology into experiences that feel intuitive and inspiring</b>. At Campfire, I've become fascinated with finding ways to showcase cutting-edge tech through both interactive demos at trade shows and digital onboarding experiences that emulate human-like interactions. The idea of working on high-profile moments for Google Cloud that span everything from live events to digital experiences feels like the perfect intersection of my technical background, design skills, and love for storytelling through interactive experiences.
          </p>

          <p className='p'>
            <b className="text-heading">Making AI Human-Centered and Approachable</b><br/>
            I believe AI is completely reshaping how we interact with technology. What excites me is finding ways to <b className="text-emphasis">make these incredibly powerful capabilities feel approachable and meaningful</b> to users. In my work, I've focused on designing AI interfaces that enhance rather than replace human creativity and decision-making. I'd love to bring this perspective to the Cloud Events team, helping create experiences that make Google Cloud's AI capabilities feel not just powerful, but genuinely useful and accessible to a wide audience.
          </p><br/>

          {/* ---------- Why I'm a Strong Fit ---------- */}
          <h2 className='h2'>Why I'm a Strong Fit</h2> 
          
          {/* Experiential Design & AI Interfaces - Section 1 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>01.</span> Experiential Design & AI Interfaces
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Designed novel AI experiences for <b className="text-emphasis">Y Combinator-backed Ocular AI</b> and <b className="text-emphasis">NetXD's</b> upcoming agentic AI document review platform, where I focused on making complex AI capabilities feel intuitive through thoughtful UI design and interaction patterns.
                      <div className="case-study-button">
                        <div className="button-content button-gray">
                          Coming Soon
                        </div>
                      </div>
                    </li>
                    <li className='li'>
                      Added new AI features to Campfire's collaboration platform, <b className="text-emphasis">designing new UX patterns for AI tools within an XR environment</b> and significantly improving user onboarding with an AI assistant that guides new users through their first experiences.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    
                    <li className='li'>
                      <b className="text-emphasis">How this helps Google Cloud:</b> The Creative Content team needs designers who can translate complex AI capabilities into engaging, understandable experiences. My background creating interactive AI interfaces across different platforms would help develop demos and experiences that showcase Google Cloud's AI offerings in ways that resonate with various audiences.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image">
                <div className="image-container">
                  <img 
                    src={netxdSample} 
                    alt="NetXD banking platform interface" 
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* User Research & Data-Driven Design - Section 2 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image right-aligned">
                <div className="image-container">
                  <img 
                    src={campfireSample} 
                    alt="Campfire collaboration platform" 
                  />
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>02.</span> User Research & Data-Driven Design
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Conducted and published <b className="text-emphasis">XR social interaction research</b> with <b className="text-emphasis">Meta Research</b> and Dartmouth, developing methodologies for evaluating avatar veracity and user experience in emerging technologies.
                      <div className="case-study-button">
                        <a href="/silvr" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-blue">
                            View Meta Quest Research
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Analyzed <b className="text-emphasis">app usage data and customer feedback</b> to identify pain points in Campfire's collaboration tools, implementing targeted improvements that <b className="text-emphasis">increased users trying collaborative tools by 98%</b> and helped secure enterprise customers including <b className="text-emphasis">Whirlpool, Dyson, and Coca-Cola</b>.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">How this helps Google Cloud:</b> Creating effective demos requires understanding what resonates with different audiences. My experience conducting formal research and analyzing user data would help ensure that the experiences we create for Google Cloud effectively communicate the value of AI products while addressing real user needs and pain points.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* Cross-Functional Collaboration - Section 3 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>03.</span> Cross-Functional Collaboration
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Lead design for Campfire's cross-functional team, working closely with <b className="text-emphasis">C-suite executives</b> on product vision while contributing to <b className="text-emphasis">technical development</b> in Unity and React to ensure design vision translates into reality.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Led Unmasked's redesign as Head of Product, managing a team of developers and junior designers that grew the app to <b className="text-emphasis">10K+ users across 45+ universities</b> with features in <b className="text-emphasis">ABC News</b> and <b className="text-emphasis">the Washington Post</b>.
                      <div className="case-study-button">
                        <a href="/unmasked" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-purple">
                            View Unmasked
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">How this helps Google Cloud:</b> Creating impactful demos and experiences requires close collaboration between design, marketing, research, and technical teams. My experience leading diverse teams through complex product development would help navigate the challenges of creating compelling experiences that balance technical innovation, audience engagement, and business goals.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image">
                <div className="image-container">
                  <img 
                    src={unmaskedSample} 
                    alt="Unmasked application interface" 
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* ---------- Summary ---------- */}
          <br/>
          <h2 className='h2'>Summary</h2>
          <p className='p'>
            I'm excited about the opportunity to bring my experience designing for AI and emerging technologies to Google Cloud's Creative Content, Demos & Experiments team. I love the challenge of making complex technology feel approachable and meaningful through thoughtful design, and I'd be thrilled to apply this passion to creating experiences that showcase Google Cloud AI's capabilities. By combining my technical background, design skills, and collaborative approach, I believe I could help create moments that make Google Cloud's technology feel tangible and exciting to audiences of all types.
          </p>
          
          <p className='p'>
            Thank you for your time and consideration. I look forward to hearing back!
          </p>
          
          {/* ---------- Contact Info ---------- */}
          <div className="contact-section">
            <div className="contact-container">
              <div className="contact-links">
                <a href="https://www.linkedin.com/in/miraram/" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>LinkedIn</a>
                <span className="separator">|</span>
                <a href="https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>Resume</a>
                <span className="separator">|</span>
                <a href="mailto:mira.ram@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>mira.ram@gmail.com</a>
                <span className="separator">|</span>
                <span className="phone">(785) 304-3812</span>
              </div>
            </div>
          </div>
          
          
          </PasswordComponent>
          
        </div>
        <Footer />
      </div>
    );
  }
}

export default GoogleCloudAICoverLetterPage; 