// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ImageScrollList from './ProjectDetailPageComponents/ImageScrollList';

import ScrollButton from '../../Components/ScrollButton';

// assets
// Import your Notion case study assets here
// Example:
// import banner from '../../assets/notion/notion_banner.png';
import floorDwellingMira from '../../assets/landing_page_2/floor_dwelling_mira.png';

// Add imports for case study images/videos
import campfireSample from '../../assets/coverletters/campfire_sample.png'; 
import netxdSample from '../../assets/coverletters/netxd_sample.png'; 
import flourishSample from '../../assets/coverletters/flourish_sample.png'; 
import unmaskedSample from '../../assets/coverletters/unmasked_sample.png'; 
import share_quest from '../../assets/campfire_collab/share_quest.mp4';
import notion_tote from '../../assets/coverletters/notion_tote.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css';
import '../../Styles/coverletter.scss';
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';

class NotionCoverLetterPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    // Replace with your Notion banner when available
    // this.background = banner;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#ed8f8a' // Pink from About page links
    
    // Adding custom styles for the video container
    this.videoStyle = {
      width: '100%',
      height: '100%', // Fixed height to match other containers
      minHeight: '400px',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: '8px'
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div key={index} className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        {/* <div className={'project-detail-page-banner'} style={{backgroundColor: '#F1F1F1', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} /> */}
        <br/><br/><br/><br/><br/>
        <div className='project-detail-page-layout'>
          {/* ---------- Title ---------- */}
          {/* ---------- Password ---------- */}
          
   
          <PasswordComponent
            sha256={'4c3bcb5392cf193fef6e5b0746955692aab68baba63f1ecd33ea95d8c313cf83'}
            color={'#39271C'}>   
          
            
          <h1 className="h1">Notion Cover Letter</h1>
          <h2 className="h2 subtitle">
            Why I'd like to design for Notion, and what I bring to the table.
          </h2>
          <br/>

          {/* ---------- About ---------- */}
          <h2 className='h2'>About</h2>
          <p className='p'>
            Thanks for stopping by! I'm Mira, a NYC-based  UX designer with <b className="text-emphasis">7+ years of experience</b> designing <b className="text-emphasis">0-to-1 solutions</b> for clients ranging from <b className="text-emphasis">Y Combinator-backed startups to Meta</b>. My work has been featured at <b className="text-emphasis">Sundance Film Festival, ABC News, and PBS</b>. I currently lead the design of enterprise 3D collaboration software at <a href="https://campfire3d.com/" target="_blank" rel="noopener noreferrer" style={{color: this.accent_color, fontWeight: 600}}>Campfire</a>, where I create <b className="text-emphasis">multi-platform, research-driven solutions</b> that balance innovation with usability. I specialize in <b className="text-emphasis">business-focused design strategy</b> rooted in thorough research and brought to life with engaging visual interfaces.
          </p> <br/>
          <div className="center-content">
            <img 
              src={floorDwellingMira} 
              alt="Mira illustration" 
              className="profile-image"
            />
          </div>
          <br/>

          {/* ---------- Why Notion? ---------- */}
          <div className="callout-section" style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }}>
            <div className="callout-container" style={{ background: 'transparent', boxShadow: 'none', padding: '0', marginLeft: 0 }}>
              <div className="callout-text left-aligned" style={{ marginLeft: 0, paddingLeft: 0, flex: '2' }}>
                <h2 className='h2'>Why Notion?</h2>
                <p className='p'>
                  <b className="text-heading">I'm a bit of a fan</b><br/>
                  I've been a Notion power user since 2018, using it for everything from managing my to-dos and integrating my daily tasks with Notion Calendar, to building automations, to writing this cover letter (hi!). I've always admired the product's balance of structure and flexibility, and I strive to bring that same clarity and intentionality to the products I design. I'd love to chat more about my ideas for improving the product!
                </p><br/>
                
                <p className='p'>
                  <b className="text-heading">Why this role?</b><br/>
                  I'm drawn to Notion's emphasis on designing new user patterns, unifying fragmented tools, and multidisciplinary work. At Campfire, I've designed and implemented novel 3D collaboration tools across desktop and XR, developing user patterns that have since been adopted by major competitors (ask me about this!). I've also worked to replace fragmented 3D design review and technical training workflows (like sharing and reviewing CAD files) with simple, seamless, and focused tools that support clear vision and product direction. As a designer at Notion, I would hope to bring this same clarity and intentionality to a product that impacts millions of people across individual users and organizations.
                </p>
              </div>
              <div className="callout-image right-aligned" style={{ flex: '1' }}>
                <div className="image-container" style={{ maxWidth: "600px", marginLeft: "20px",marginTop: "60px" }}>
                  <img 
                    src={notion_tote} 
                    alt="Notion tote bag" 
                    style={{ borderRadius: '8px', width: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <br/>

          {/* ---------- Why I'm a Strong Fit ---------- */}
          <h2 className='h2'>Why I'm a strong fit</h2> 
          
          {/* Design, Code, and Strategy - Section 1 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image left-aligned">
                <div className="image-container">
                  <video 
                    src={share_quest}
                    alt="Campfire share quest functionality" 
                    controls
                    autoPlay
                    muted
                    loop
                    className="video-element"
                    style={this.videoStyle}
                  ></video>
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>01.</span> Design, code, and strategy
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Built Campfire's cross-platform features across desktop, tablet, and XR, adapting to platform-specific interaction patterns while designing differentiated features that helped secure contracts with Coca-Cola, PepsiCo, Dyson, and Whirlpool.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Work closely with engineers and contribute directly to front-end development in <b className="text-emphasis">React.js and Unity</b>. (I also have an <b className="text-emphasis">M.S. in Computer Science from Dartmouth</b>, and built this portfolio site from scratch!)
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Notion Alignment:</b> I bring a mix of design precision, technical fluency, and strategic thinking to a team that values craft and thoughtful infrastructure.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* 0-to-1 Product Design in Fast-Moving Teams - Section 2 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>02.</span> 0-to-1 product design in fast-moving teams
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Shipped 0-to-1 products for <b className="text-emphasis">10+ early- to mid-stage startups</b>, helping secure contracts with Fortune 500 companies (@ Campfire, NetXD), contributing to successful acquisition (@ Trusst → K Health), and building products recognized by major media outlets (@ Unmasked).
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      At Campfire and NetXD, I've thrived in <b className="text-emphasis">small, fast-moving teams</b>, often wearing multiple hats, from shaping product strategy to prototyping and developer handoff.
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Notion Alignment:</b> I'm comfortable owning projects end-to-end and collaborating across disciplines. This role's emphasis on autonomy, breadth, and team collaboration aligns perfectly with how I work.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image right-aligned">
                <div className="image-container">
                  <img 
                    src={netxdSample} 
                    alt="NetXD banking platform interface" 
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* User-Centered Approach - Section 3 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image left-aligned">
                <div className="image-container">
                  <img 
                    src={campfireSample} 
                    alt="Campfire collaboration platform" 
                  />
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>03.</span> User-centered approach
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Conducted and published XR social interaction research with Meta Research and Dartmouth, developing methodologies for evaluating avatar veracity.
                      <div className="case-study-button">
                        <a href="/silvr" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-blue">
                            View Meta Quest Research
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Analyzed usage analytics and user feedback to identify usability issues in Campfire's tools, implementing improvements that increased collaborative tool usage by <b className="text-emphasis">98%</b>.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Notion Alignment:</b> I care about user empathy and the craft of interface design. As someone already familiar with Notion and its product evolution over the years, I'd love to help refine and evolve it for the next generation of users.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* ---------- Summary ---------- */}
          <br/>
          <h2 className='h2'>Summary</h2>
          <p className='p'>
            I hope to bring my blend of design strategy, technical background, and product intuition to Notion. As both a long-time user and an experienced designer, I'd love to contribute to a product I admire and use every day.
          </p>
          
          <p className='p'>
            Thank you for your time and consideration. I look forward to hearing back!
          </p>
          
          {/* ---------- Contact Info ---------- */}
          <div className="contact-section">
            <div className="contact-container">
              <div className="contact-links">
                <a href="https://www.linkedin.com/in/miraram/" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>LinkedIn</a>
                <span className="separator">|</span>
                <a href="https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>Resume</a>
                <span className="separator">|</span>
                <a href="mailto:mira.ram@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>mira.ram@gmail.com</a>
                <span className="separator">|</span>
                <span className="phone">(785) 304-3812</span>
              </div>
            </div>
          </div>
          
          
          </PasswordComponent>
          
        </div>
        <Footer />
      </div>
    );
  }
}

export default NotionCoverLetterPage; 