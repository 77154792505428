// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ImageScrollList from './ProjectDetailPageComponents/ImageScrollList';

import ScrollButton from '../../Components/ScrollButton';

// assets
// Import your Google case study assets here
// Example:
// import banner from '../../assets/google/google_banner.png';
import floorDwellingMira from '../../assets/landing_page_2/floor_dwelling_mira.png';

// Add imports for case study images/videos
import campfireSample from '../../assets/coverletters/campfire_sample.png'; 
import netxdSample from '../../assets/coverletters/netxd_sample.png'; 
import flourishSample from '../../assets/coverletters/flourish_sample.png'; 
import unmaskedSample from '../../assets/coverletters/unmasked_sample.png'; 

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css';
import '../../Styles/coverletter.scss';
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';

class GoogleCoverLetterPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    // Replace with your Google banner when available
    // this.background = banner;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#ed8f8a' // Pink from About page links
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div key={index} className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        {/* <div className={'project-detail-page-banner'} style={{backgroundColor: '#F1F1F1', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} /> */}
        <br/><br/><br/><br/><br/>
        <div className='project-detail-page-layout'>
          {/* ---------- Title ---------- */}
          {/* ---------- Password ---------- */}
          
   
          <PasswordComponent
            sha256={'3ce0930d33ddd1af644b7f1e733caf725f1e650b73483d474afa92cd131b1afb'}
            color={'#39271C'}>   
          
            
          <h1 className="h1">Google Cover Letter</h1>
          <h2 className="h2 subtitle">
            Why I'd like to design for Google, and what I bring to the table.
          </h2>
          <br/>

          {/* ---------- About ---------- */}
          <h2 className='h2'>About</h2>
          <p className='p'>
            Thanks for stopping by! I'm Mira, a NYC-based  UX designer with <b className="text-emphasis">7+ years of experience</b> designing <b className="text-emphasis">0-to-1 solutions</b> for clients ranging from <b className="text-emphasis">Y Combinator-backed startups to Meta</b>. My work has been featured at <b className="text-emphasis">Sundance Film Festival, ABC News, and PBS</b>. I currently lead the design of enterprise 3D collaboration software at <a href="https://campfire3d.com/" target="_blank" rel="noopener noreferrer" style={{color: this.accent_color, fontWeight: 600}}>Campfire</a>, where I create <b className="text-emphasis">multi-platform, research-driven solutions</b> that balance innovation with usability. I specialize in <b className="text-emphasis">business-focused design strategy</b> rooted in thorough research and brought to life with engaging visual interfaces.
          </p> <br/>
          <div className="center-content">
            <img 
              src={floorDwellingMira} 
              alt="Mira illustration" 
              className="profile-image"
            />
          </div>
          <br/>

          {/* ---------- Why Google? ---------- */}
          <h2 className='h2'>Why Google?</h2>
          <p className='p'>
            <b className="text-heading">Google's Legacy of Innovation</b><br/>
            From entering Google Doodle contests as a kid, I've been inspired by Google's ability to <b className="text-emphasis">merge creativity and technology</b>. This grew into a lifelong appreciation for Google's <b className="text-emphasis">user-first philosophy, experimental mindset, and scale of impact</b> as I pursued design. Drawing from my experience building intuitive multi-platform experiences and implementing research-backed design decisions, I'm eager for the opportunity to contribute to Google's culture of innovation.
          </p>
          
          <p className='p'>
            <b className="text-heading">Why Google Labs?</b><br/>
            Google Labs stands at the forefront of <b className="text-emphasis">human-computer interaction</b>, pioneering breakthroughs in AI, XR, and emerging interfaces. With my background in <b className="text-emphasis">working with startups, AI experiences, and published XR research</b>, I'm particularly drawn to a team that values creativity, adaptability, and designing for what's next.
          </p>

          <p className='p'>
            <b className="text-heading">Human-Centered Design for AI</b><br/>
            I believe that AI has completely changed the tech landscape and is going to permanently reshape the way designers, developers, and PMs work in tech. When designing a new product, I want to be able to harness AI to streamline the process of adding "table stakes" functionality, so I can focus on creating groundbreaking, differentiating features. <b className="text-emphasis">I hope to be able to build AI products that can be used to enhance human creativity rather than replace it</b>, and I believe that Google Labs is the perfect place to do so.
          </p><br/>

          {/* ---------- Why I'm a Strong Fit ---------- */}
          <h2 className='h2'>Why I'm a Strong Fit</h2> 
          
          {/* AI and Emerging Technologies - Section 1 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>01.</span> Experience in AI and Emerging Technologies
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Designed novel AI experiences for <b className="text-emphasis">Y Combinator-backed Ocular AI</b> and <b className="text-emphasis">NetXD's</b> upcoming agentic AI document review platform.
                      <div className="case-study-button">
                        <div className="button-content button-gray">
                          Coming Soon
                        </div>
                      </div>
                    </li>
                    <li className='li'>
                    Integrated new AI features into Campfire's collaboration platform in an upcoming release, <b className="text-emphasis">designing new UX patterns for AI tools within an XR platform</b> and improving user onboarding with the introduction of an AI assistant. <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    
                    <li className='li'>
                      <b className="text-emphasis">Google Labs Alignment:</b> Google Labs is pioneering experimental AI and multimodal interfaces like NotebookLM. My experience designing for emerging technologies would help create intuitive experiences that make these cutting-edge tools accessible to users, balancing creativity with practicality across platforms.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image">
                <div className="image-container">
                  <img 
                    src={netxdSample} 
                    alt="NetXD banking platform interface" 
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* User-Centered Research & Strategy - Now Section 2 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image right-aligned">
                <div className="image-container">
                  <img 
                    src={campfireSample} 
                    alt="Campfire collaboration platform" 
                  />
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>02.</span> User-Centered Research & Strategy
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Conducted and published <b className="text-emphasis">XR social interaction research</b> with <b className="text-emphasis">Meta Research</b> and Dartmouth, developing methodologies for evaluating avatar veracity.
                      <div className="case-study-button">
                        <a href="/silvr" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-blue">
                            View Meta Quest Research
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Analyzed <b className="text-emphasis">app usage data and customer feedback</b> to identify usability pain points in Campfire's collaboration tools, implementing targeted improvements that <b className="text-emphasis">increased users trying collaborative tools by 98%</b> and secured enterprise customers including <b className="text-emphasis">Whirlpool, Dyson, and Coca-Cola. </b>
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    {/* <li className='li'>
                      Led comprehensive user testing at Flourish, translating findings into intuitive health tracking interfaces that improved data visualization and secured <b className="text-emphasis">National Science Foundation (NSF) funding</b>.<div className="case-study-button">
                        <a href="/flourish" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-light-blue">
                            View Flourish
                          </div>
                        </a>
                      </div>
                    </li> */}
                    <li className='li'>
                      <b className="text-emphasis">Google Labs Alignment:</b> I'm drawn to how Google Labs transforms complex technologies into intuitive tools through rigorous research and testing. My background in data-driven design decisions and user research methodologies would align with Labs' approach to creating experimental products that solve real user problems while pushing technological boundaries.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* Now Cross-Functional Leadership & Innovation in section 3 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>03.</span> Cross-Functional Leadership & Innovation
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Lead design for Campfire's cross-functional team, collaborating with <b className="text-emphasis">C-suite executives</b> on product vision while contributing to <b className="text-emphasis">technical development</b> in Unity and React.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Spearheaded Unmasked's redesign as Head of Product, managing a cross-disciplinary team of developers and junior designers that grew the app to <b className="text-emphasis">10K+ users across 45+ universities</b> with features in <b className="text-emphasis">ABC News</b> and <b className="text-emphasis">the Washington Post</b>.
                      <div className="case-study-button">
                        <a href="/unmasked" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-purple">
                            View Unmasked
                          </div>
                        </a>
                      </div>
                    </li>
                    {/* <li className='li'>
                      Led UX for <b className="text-emphasis">11 early-stage startups</b> at DALI Innovation Lab, shipping products while developing collaboration framework for <b className="text-emphasis">70+ member organization</b>.
                    </li> */}
                    <li className='li'>
                      <b className="text-emphasis">Google Labs Alignment:</b> Google Labs' cross-functional collaboration brings experimental concepts to life. My experience leading diverse teams through ambiguous, early-stage product development would help navigate the challenges of creating groundbreaking experiences that balance technical innovation with human needs and business goals.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image">
                <div className="image-container">
                  <img 
                    src={unmaskedSample} 
                    alt="Unmasked application interface" 
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* ---------- Summary ---------- */}
          <br/>
          <h2 className='h2'>Summary</h2>
          <p className='p'>
            I strive to be able to push the boundaries of interaction design at Google, offering my experience in designing flexible interfaces for AI, XR, and emerging technologies as well as my background in research and cross-functional leadership. My work has always been driven by a passion for blending technology, creativity, and human insight—values. I would love the chance to discuss how I can contribute to the next generation of transformative, human-centered experiences at Google.
          </p>
          
          <p className='p'>
            Thank you for your time and consideration. I look forward to hearing back!
          </p>
          
          {/* ---------- Contact Info ---------- */}
          <div className="contact-section">
            <div className="contact-container">
              <div className="contact-links">
                <a href="https://www.linkedin.com/in/miraram/" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>LinkedIn</a>
                <span className="separator">|</span>
                <a href="https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>Resume</a>
                <span className="separator">|</span>
                <a href="mailto:mira.ram@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>mira.ram@gmail.com</a>
                <span className="separator">|</span>
                <span className="phone">(785) 304-3812</span>
              </div>
            </div>
          </div>
          
          
          </PasswordComponent>
          
        </div>
        <Footer />
      </div>
    );
  }
}

export default GoogleCoverLetterPage; 