// libraries
import React from 'react';
import { Helmet } from 'react-helmet';

import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import screen1 from '../../assets/unmasked/1_welcome.png';
import screen2 from '../../assets/unmasked/2_night_tw.png';
import screen3 from '../../assets/unmasked/3_night_notifs.png';
import screen4 from '../../assets/unmasked/4_night_messaging.png';

import screen5 from '../../assets/unmasked/5_welcome_back.png';
import screen6 from '../../assets/unmasked/6_at_dartmouth.png';
import screen7 from '../../assets/unmasked/7_cheating.png';

import screen8 from '../../assets/unmasked/8_posting.png';
import screen9 from '../../assets/unmasked/9_filters.png';
import screen10 from '../../assets/unmasked/10_create_post.png';

import screen11 from '../../assets/unmasked/11_new_main.png';
import screen12 from '../../assets/unmasked/12_account_creation.png';
import screen13 from '../../assets/unmasked/13_community_guidelines.png';

import screen14 from '../../assets/unmasked/screen14.png';
import screen15 from '../../assets/unmasked/screen15.png';
import screen16 from '../../assets/unmasked/screen16.png';

import screen17 from '../../assets/unmasked/screen17.png';
import screen18 from '../../assets/unmasked/screen18.png';
import screen19 from '../../assets/unmasked/screen19.png';

import screen20 from '../../assets/unmasked/screen20.png';
import screen21 from '../../assets/unmasked/screen21.png';
import screen22 from '../../assets/unmasked/screen22.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';

class UnmaskedProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/unmasked_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#926BE4'
    this.tags = ["UI/UX", "Graphic Design"];
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Unmasked</h1>
          <h2 className="h2">
          <a target="_blank" href="https://www.unmaskedproject.com/" style={{color: this.accent_color}}> Unmasked</a> is an anonymous social media app for college students
          focused on mental health. The app is available on the <a target="_blank" href="https://apps.apple.com/us/app/unmasked-project/id1439705803?ign-itscg=30200&ign-itsct=apps_box_badge" style={{color: this.accent_color}}>iPhone </a>
          and <a target="_blank" href="https://play.google.com/store/apps/details?id=com.unmasked&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" style={{color: this.accent_color}}>Android </a>app stores.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(this.tags)}
          </div>

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
            While a significant number of college students struggle with mental illness,
            college campuses often fail to provide sufficient resources for students
            seeking help. Students at Dartmouth identified this issue at our school,
            and proposed a student-led alternative to the existing (but lacking)
            mental health resources where students could provide emotional
            support for each other in a safe, anonymous space, free from stigma.
          </p>
          <p className='p'>
            I joined the Unmasked team as a designer in 2018, when Unmasked
            was still early in its development. I now serve as the Head of Product.
            In January 2020, Unmasked officially launched for Dartmouth students.
            Once our user base began to grow, several pain-points with the existing
            designs became apparent. The app suffered from usability issues,
            and the UI was in need of an upgrade in aesthetics. For these reasons,
            I decided to spearhead a full redesign of the app. While design work
            for the app is still ongoing, this case study will focus on the most
            significant portion of the redesign which occurred from June – August 2020.
          </p>

          {/* Graphical list idk */}
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June – August 2020 (3 mo)",
                         "Head of Product",
                         "Rapid Iteration\nWireframing\nPrototyping\nUsability Testing\nUser Interviewing\nFigma",
                         "Mira Ram\nCallie Page\nYoko Urano\nWylie Kasai\nDylan Lew\nSanat Mohapatra"]}
          />

          {/* The Old Unmasked */}
          <h2 className='h2'>The Old Unmasked</h2>
          <p className='p'>
            Unmasked was designed around the following key functionalities
            for engaging with the student community, defined after initially
            interviewing Dartmouth students:
          </p>
          <ul className='ul'>
            <li className='li'>Creating posts, and being able to sort and filter the posts of others</li>
            <li className='li'>Conversing with other users through comments on posts and direct messages (DMs)</li>
            <li className='li'>Supporting other users by "liking" their posts with a count only visible to the original poster</li>
            <li className='li'>Implementing a moderation system to maintain a safe community</li>
            <li className='li'>Easy access to relevant mental health resources</li>
            <li className='li'>The app itself should be inviting, friendly, and delightful to use</li>
          </ul>

          <p className='p'>
            With these features in mind, the previous designs were created over many iterations.
            Below is the set of designs that were ultimately implemented in the January 2020 release:
          </p>

          <ScreenWithCaptionList
            screens={[screen1, screen2, screen3, screen4]}
            titles={["Login/Sign up", "Dashboard", "Connections", "Messages"]}
            text={[
              `Lacked scalability for an expanding list of schools
              Calming design, but difficult to read
              Lacked robust onboarding experience`,
              `Posts all looked the same, difficult to differentiate posts
              Filtering was not robust enough
              Creating a post was not robust enough`,
              `Too many different types of alerts in one place
              Difficult to sort through post notifications`,
              `Lacked a method for differentiating conversations with different users
              Lacked ability to interact with received messages`
            ]}
          />

          <ProjectAnnouncementComponent
            color={this.accent_color}
            title = "THE GOAL"
            text = {`How might we foster a welcoming community for college
                     students seeking emotional support?`}
          />

          {/* Iterating */}
          <h2 className='h2'>Iterating</h2>
          <h3 className='h3'>Dashboard</h3>
          <p className='p'>
            The calming background illustrations within the app (initially created by Theia Qu) are a
            key part of Unmasked's design. In app feedback, users often described the mountains and
            trees in the app's background and home page icon as the most memorable part of the app.
            When we set out to redesign the app, this would mean rebranding Unmasked as well.
            Therefore, our goal was to improve usability and aesthetics, while maintaining the
            familiar aspects of the app that users enjoyed.
          </p>
          <p className = 'p'>
            We also aimed to improve and expand the post filtering functionality, which
            previously only sorted by "most recent" and "number of likes." Prior to the
            redesign, users could only add trigger warning labels to their post (the
            formatting for which also needed usability adjustments). In our early
            iterations, we began including general tags while considering other
            ways to expand filtering.  Below are a few of our first iterations:
          </p>

          <ScreenWithCaptionList
            screens={[screen5, screen6, screen7]}
          />

          <p className='p'>
            Ultimately, we settled on a design that took bits and pieces of all
            of our initial designs. We aimed for posts to be readable, but
            differentiable, for posts to be searchable with expanded filtering,
            and for posting to include expanded features. Below are the final designs:
          </p>


          <ScreenWithCaptionList
            screens={[screen8, screen9, screen10]}
            titles={["Dashboard", "Filters (New)", "Create a Post (New)"]}
            text={[
              `Posts now display tags, identicons, titles, and a bookmark button.
              Posts will  fit the length of a post rather than remaining a uniform height
              A search bar and filter button have been added
              The background is a solid color for readability and to better fit modern minimalist design conventions`,
              `Expanded sorting, including "most recent," "most liked," "most discussed," and "random"
              Users can now sort by tags
              Users are also able to click on a post's tag to immediately filter by that tag`,
              `Posts have moved to a new page to create more room for the post creation experience and newer features
              Users can now add titles to their posts, particularly useful when users write longer posts`
            ]}
          />

          <h2 className='h2'>
            Onboarding
          </h2>
          <p className='p'>
            Prior to the redesign, the app lacked a robust onboarding experience.
            In the previous version of the app, users simply set up their
            user credentials and were made to read through the lengthy community
            guidelines and terms of service before indicating that they agreed
            to each set of terms, regardless of whether they had actually read
            any of it. We learned very quickly that users weren't actually
            reading the community guidelines when it came as a shock to
            many of our users that the moderation team would decrypt anonymous
            user data in the case of a threat to a user's life.

            Additionally, the previous login/sign up experience lacked scalability for
            an expanding list of schools. The previous design, while calming in aesthetics,
            was also difficult to read.

            When designing an onboarding experience, we aimed to:
          </p>

          <ul className='ul'>
            <li className='li'>Present important information in a clear and easy-to-digest format</li>
            <li className='li'>Make sure users actually read the terms</li>
            <li className='li'>Create a delightful onboarding experience that persuades users to the app</li>
          </ul>


          <p className='p'>Below are our first few iterations:</p>

          <ScreenWithCaptionList
            screens={[screen14, screen15, screen16]}
          />

          <p className='p'>Our final designs evolved from these initial mockups to feature a more fleshed out visual
          design, including inviting animated graphics:</p>

          <ScreenWithCaptionList
            screens={[screen11, screen12, screen13]}
            titles={["Login/Sign up", "Create Account", "Community Guidelines"]}
            text={[
              `Refreshed background illustration
              Includes an app description
              Improved readability `,
              `Now detects school from email input, rather than having the user select from a long dropdown
              Solid colored background for improved readability`,
              `Guidelines split into 4 different screens with graphics to improve digestibility
              Users cannot navigate to the next screen until a certain number of time has passed, encouraging the user to read the text
              Inviting graphics for added delight`
            ]}
          />

<h2 className='h2'>
            Connections
          </h2>
          <p className='p'>
            The original version of the app lumped notifications and messages into the single category
            of "Connections." We found through our early users of the app that while users would eventually
            understand the information architecture, the connections page still served as a point of friction
            for initial users. The app also displayed many different types of notifications that users
            sometimes struggled to sort through in regard to notification type and how new the notification
            was.

            When designing an onboarding experience, we aimed to:
          </p>

          <ul className='ul'>
            <li className='li'>Split Connections into Notifications ("Alerts") and List of DMs ("Inbox")</li>
            <li className='li'>Create a clearer distinction between different types of notifications and read/unread notifications</li>
            <li className='li'>Bundle notifications in a way that makes them easier to understand at a glance</li>
          </ul>


          <p className='p'>Below are our first few iterations:</p>

          <ScreenWithCaptionList
            screens={[screen17, screen18, screen19]}
          />

          <p className='p'>With each of these iterations, it was particularly important for us to consider the different
          microinteractions a user might take when interaction with notifications. For instance, when eight people support
          a user's post, will the notification card always group them based on the supported post, or will a new bundle
          start every time a user checks or refreshes their notifications? Would the app's notification badge display
          eight new notifications or just one? Does the notification move out of "new" once a user has opened the page or
          does it only move once you've tapped on a notification card? Our final designs below addressed these concerns and more: </p>

          <ScreenWithCaptionList
            screens={[screen20, screen21, screen22]}
            titles={["Alerts (New)", "Inbox (New)", "Message Thread"]}
            text={[
              `Titled 'Alerts' for clarity and space real estate
              Separated from inbox
              Colorful notification icons, grouped by time of day and "new" indicator
              Tap on notification to see related post`,
              `Separated from alerts
              Ability to hide chats
              Fewer clicks to access specific messages`,
              `Expanded features to better fit modern messaging conventions such as interactions and replying to specific messages
              Nickname defaults to 'Anonymous Friend' but can be changed`
            ]}
          />


          <h2 className='h2'>
            Challenges & Takeaways
          </h2>
          <p className='p'>
            During and after our 3-month sprint to redesign and rebrand,
            we faced several challenges in designing the app:
          </p>
          <p className='p'>
            <b style={{fontWeight:600}}>Balancing improvements with familiarity</b> <br/>
            It's a known fact that people are adverse to change.
            I have personally found myself guilty of complaining
            when a social media app I use regularly drastically
            changes some part of its UI. The redesign would, indeed,
            be a drastic change, but we aimed to keep as many features
            in the same place so not to confuse old users. It is for
            this reason that we chose to only include one completely
            new feature (tagging) in the first phase of the redesign update.
            After releasing the update, we eventually added other changes
            such as the separation of connections into "notifications" and
            "messages," and a switch between your school message board, and a
            message board with every Unmasked school on the dashboard.
          </p>

          <p className='p'>
          <b style={{fontWeight:600}}>Trusting design intuition</b> <br/>
            Having also worked on the previous version of the Unmasked design,
            I felt that an overhaul might be necessary before the app had even
            been launched. I was still quite new to design when I started designing
            Unmasked, and through education and professional experience, my design
            skill and understanding naturally grew. I was able to look back at the
            old designs I'd made in 2018 with a more critical eye. That being said,
            I was a bit afraid to design purely based on my own intuitions, so I
            waited for formal user feedback after the app's launch before deciding
            which direction to take.
          </p>

          <p className='p'>
          <b style={{fontWeight:600}}>Slow development</b> <br/>
            Because most Unmasked employees and
            volunteers are students or alumnus with
            full-time positions, development of the
            redesign was extremely slow. The developers
            also took the redesign as an opportunity to
            refactor the code. As a result, the redesign
            was only released in September 2021. However,
            this slow development period gave the design
            team plenty of time to polish features and prepare
            for the future.
          </p>

          <h2 className='h2'>
            Next Steps
          </h2>
          <p className='p'>
            The redesigned app is available for
            <a target="_blank" href="https://apps.apple.com/us/app/unmasked-project/id1439705803?ign-itscg=30200&ign-itsct=apps_box_badge" style={{color: this.accent_color}}> iPhone </a>
            and <a target="_blank" href="https://play.google.com/store/apps/details?id=com.unmasked&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" style={{color: this.accent_color}}>Android </a>
            on their respective app stores.  As we
            gain more users and review feedback, we will
            continue to make changes to the design.
          </p>
          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default UnmaskedProjectPage;
