// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import Image from "react-image-enlarger";
import ScrollButton from '../../Components/ScrollButton';
// assets
import PasswordComponent from '../../Components/PasswordComponent';
import sundance1 from '../../assets/bywater/sundance 1.jpg';
import sundance2 from '../../assets/bywater/sundance 2.jpg';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


function ZoomableImg({ src }) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <div style={{ padding: "0.25vw", overflow:'hidden', maxHeight: '500px'}}>
      <Image
        style={{ width: "300px", height: "auto", overflow: 'hidden' }}
        zoomed={zoomed}
        src={src}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
    </div>
  );
}


class ByWaterProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/by_water_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#769B85'

  }

  // <div className="gallery-container gallery-container-margin">


  //               <a target="_blank" href={drawing34}>
  //                 <img src={drawing34}/>
  //               </a>

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }


  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">By Water</h1>
          <h2 className="h2">
          An unlikely hero's journey into his own memories becomes a vehicle for reconciliation and healing for himself and his sibling.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Motion", "Illustration"])}
          </div>


          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          <a target="_blank" href="https://bywaterfilm.com/" style={{color: this.accent_color}}> By Water </a>is an animated short film directed by Iyabo Kwayana. The film has been screened at <a target="_blank" href="https://festivalplayer.sundance.org/sundance-film-festivall-2023-prod/play/639532a3a9fdd500548bcb7a/638a1bfb71f1620064245b53" style={{color: this.accent_color}}>Sundance Film Festival</a>, BlackStar Film Festival, New Hampshire Film Festival, Well Read Black Girl Film Festival, and New Orleans Film Festival. I worked as an animator on this film, leading the last stretch of animation production for this project.
          <br></br>
          </p>
          <h2 className='h2'>Trailer</h2>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/715816487?h=5471d9f07d"
            style={{border:'0', borderRadius:'10px'}}
            width="100%"
            height="480"
            frameborder="0"
            allowfullscreen></iframe>

          <br/><br/>
          <p className='p'>
          A reel of my own work on this project is available below the cut.
          </p>

          <PasswordComponent
        sha256={'fc97013f69b1cbc4d4b26220fb8903991343024fd159fc6013974eda2a09d2c5'}
        color={this.accent_color}>

            {/* Graphical list idk */}
            <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["May - June 2022 \n(1 mo)",
                         "Animator / Project Manager",
                         "Animation\nStoryboarding\nIllustration\nAfter Effects\nPhotoshop\nClip Studio Paint\nProcreate\nBlender",
                         "Mira Ram (Animator)\nIyabo Kwayana (Director)\nCharlotte Hong Bee Her (Animation Director)\nAlex Davis (Animator)\n"]}
          />

          <p></p>
          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/UDoq20BW0O4"
            title="Mira&#39;s By Water Reel"
            frameborder="0"
            style={{border:'0', borderRadius:'10px'}}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
            <p className='p'>
            I was brought in to work on this project during the final stretch of production. At this point, the majority of the short film's animation work had already been completed by the previous animation team, led by Charlotte Hong Bee Her. Due to the strict time constraint, I had very little communication with the previous team and had to work quickly while making sure to match the film's animation style. I also brought fellow classmate and animator Alex Davis into this project to serve as an animation assistant to manage workload.
            </p><p className='p'>
            Backgrounds and textures were replicated from the previous team's assets in Photoshop, petal and house linework was drawn in CSP and Procreate, bird swarm was simulated in Blender, and scenes were compiled and animated in After Effects.
            </p>

            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
              <ZoomableImg key={sundance1} src={sundance1} />
              <ZoomableImg key={sundance2} src={sundance2} />
            </div><br/>
            <p className='p'>
            As someone who is not a professional animator and primarily a hobbyist illustrator, I am incredibly grateful that I had the opportunity to learn by studying the work of the previous animation group. I also had the pleasure of meeting the full production team the Sundance premiere of By Water!
            </p>
          </PasswordComponent>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default ByWaterProjectPage;
