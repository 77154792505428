import React, { Component } from "react";
import camelCase from "lodash/camelCase";
import Grid from "./ProjectDataGrid";
import { easings } from "react-stonecutter";
import { projectData } from "./ProjectData";
import {itemSize, scale} from './Sizing';
import HeaderBar from "../../Components/HeaderBar";

import ScrollButton from '../../Components/ScrollButton';

import './ProjectPage.scss'
import '../../Styles/Fonts.scss'
import Footer from "../ProjectDetailPage/ProjectDetailPageComponents/Footer";
import ScreeningButton from "../../Components/ScreeningButton/ScreeningButton";
import { checkMobile } from "../../Styles/ScreenCalcs";

const layouts = ["Pinterest", "Simple"];
const enterExitStyles = [
  "Simple",
  "Skew",
  "Newspaper",
  "Fold Up",
  "From Center",
  "From Left to Right",
  "From Top",
  "From Bottom"
];

export default class extends Component {
  constructor(props) {
    super(props);

    this.true_data = projectData;

    this.tags = new Set();
    this.tags.add("Featured");
    this.tags.add("All");
    this.true_data.forEach((project) => {
        project.tags.forEach((tag) => {
            this.tags.add(tag);
        });
    });
    this.componentCount = 0;

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let initialTag = params.get('tag')
    if(initialTag == null) {
      initialTag = 'Featured'
    }
    this.selectedTag = initialTag ? initialTag : "Featured"
    this.state = {
      data: this.true_data.filter((project) => {
        return (project.tags.includes(initialTag));
      }),
      useCSS: false,
      layout: camelCase(layouts[0]),
      enterExitStyle: camelCase(enterExitStyles[0]),
      duration: 800,
      stiffness: 60,
      damping: 14,
      columns: 5,
      gutters: 15,
      easing: easings.cubicOut
    };
  }

  selectTag = (tag) => {
    let data = this.true_data.filter((project) => {
      if (tag == "All") return true;
      else return (project.tags.includes(tag));
    });
    this.selectedTag = tag;
    this.setState({
      data: data
    });
  };

  generateTagSelectingButtons() {
    const spacer = '\u00a0\u00a0\u00a0'.repeat(1);
    let tagButtons = Array.from(this.tags).map((tag) => {
      return (
        <div className={"project-page-selection-button project-page-selection-text font-small" + (this.selectedTag == tag ? " active" : " ")} >
          <ScreeningButton onClick={() => this.selectTag(tag)} text={spacer + tag + spacer} active={this.selectedTag == tag} color='pink'/>
        </div>
      )
    });
    return tagButtons;
  }


  em(numEm) {
    let scaled = numEm * scale();
    return scaled.toString() + "em";
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.forceUpdate();
  }

  render() {
    const { data, ...gridProps } = this.state;
    const {
      useCSS,
      layout,
      enterExitStyle,
      columns,
      gutters,
      stiffness,
      damping,
      duration,
      easing
    } = this.state;

    const itemHeight = layout === "simple" ? 190 : null;
    let animationDelay = this.componentCount;
    const items = data.map(project => {
      let generateTags = () => {
        return project.tags.map((tag) => {
          return <div className="project-grid-square-tag font-x-small"><div className="project-grid-tag-text">{tag}</div></div>
        })
      };
      animationDelay = animationDelay + 0.25;
      let style = { "--delay": animationDelay + "s"};
      return (
        <div
          key={project.title}
          className="project-grid-square"
          style={{
            width: itemSize(),
            height: itemSize()
          }}
        >
          <a href={project.link}>
            <div className="project-grid-square-foreground" style={style}>
              <div className="project-grid-square-title font-medium-large">{project.title} <span style={{fontWeight:400}}>{project.sub}</span></div>
              <div className="project-grid-square-subtitle font-small">{project.description}</div>
              <div className="project-grid-square-tag-section">
                {generateTags()}
              </div>
            </div>
            <div className="project-grid-square-background" style={style}>
              <img src={"/assets/" + project.imageSrc} className="project-grid-square-background-img"/>
            </div>
          </a>
        </div>
      );
    });
    if (!checkMobile())
    {
      return (
        <div className="project-page">
          <HeaderBar />
          <ScrollButton />
          <div className="project-page-container">
            <div className="project-page-selection">
              {this.generateTagSelectingButtons()}
            </div>
            <div className="project-page-grid-wrapper">
              <Grid
                itemHeight={itemHeight}
                measured={layout !== "simple"}
                {...gridProps}
                responsive={true}
              >
                {items}
              </Grid>
            </div>
          </div>
          <Footer/>
        </div>
      );
    }
    return (
      <div className="project-page">
        <HeaderBar />
        <ScrollButton />
        <div className="project-page-container">
          <div className='project-page-rectangle'/>
          <div className="project-page-selection">
            {this.generateTagSelectingButtons()}
          </div>
          <div className="project-page-grid-wrapper">
            <Grid
              itemHeight={itemHeight}
              measured={layout !== "simple"}
              {...gridProps}
              responsive={true}
            >
              {items}
            </Grid>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}
