import React from 'react';
import {
  Link,
  NavLink
} from 'react-router-dom';

import { fallDown as Menu } from 'react-burger-menu';
import "./HeaderBar.css";
// import "./HeaderBurgerMenu.scss";
import "./HeaderBurgerMenu2.scss";
import "../Styles/Fonts.scss";
import Logo from '../assets/mira_logo.svg';

import burger from '../assets/burger.svg';
import cross from '../assets/cross.svg';
import ScreeningButton from './ScreeningButton/ScreeningButton';

import "../Styles/Fonts.scss";
import { checkMobile } from '../Styles/ScreenCalcs';

class HeaderBar extends React.Component {
  constructor(props) {
    super(props)
    this.colorset = 'def'
    if(this.props.colorset){
      this.colorset = this.props.colorset;
    }
    this.state = { isMobile: checkMobile(), menuOpen: false}
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.setState({ isMobile: checkMobile() });
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  isActive = (url) => {
    return window.location.pathname == url ? ' active ' : '';
  }

  render() {
    let activeClassName = "active";
    let currentURL = window.location.pathname;
    if (this.state.isMobile) {
      return (
        <div className="mobile-header-bar">
          <div className="router-navbar-wrapper" id={this.colorset}>
            <div className="router-navbar-inner-wrapper">
              <Link to='/'>
                <svg className="logo" width="45" height="55" viewBox="0 0 45 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="45" height="55" fill="transparent" />
                  <path shape-rendering="auto" fill-rule="evenodd" clip-rule="evenodd" d="M39.5967 0.497098C35.3382 -1.0529 30.6294 1.14284 29.0794 5.40142L24.464 18.082C23.8612 18.0227 23.2499 17.9923 22.6314 17.9923C21.9256 17.9923 21.2291 18.0319 20.5438 18.1089L15.9188 5.40167C14.3688 1.14309 9.66001 -1.05265 5.40143 0.497343C1.14285 2.04734 -1.0529 6.75612 0.497097 11.0147L6.48453 27.465C4.99112 30.133 4.1398 33.2091 4.1398 36.484C4.1398 46.6966 12.4188 54.9756 22.6314 54.9756C32.8441 54.9756 41.1231 46.6966 41.1231 36.484C41.1231 33.0926 40.2101 29.9145 38.6166 27.182L44.5011 11.0145C46.0511 6.75588 43.8553 2.04709 39.5967 0.497098ZM24.3527 27.9964C23.663 26.3718 21.3605 26.3718 20.6708 27.9964L19.0905 31.7185C18.9221 32.1152 18.6299 32.447 18.2576 32.6642L14.3966 34.9175C13.074 35.6894 13.074 37.6004 14.3966 38.3723L18.2576 40.6255C18.6299 40.8428 18.9221 41.1745 19.0905 41.5713L20.6708 45.2934C21.3605 46.9179 23.663 46.9179 24.3527 45.2934L25.933 41.5713C26.1014 41.1745 26.3936 40.8428 26.7659 40.6255L30.6269 38.3723C31.9495 37.6004 31.9495 35.6894 30.6269 34.9175L26.7659 32.6642C26.3936 32.447 26.1014 32.1152 25.933 31.7185L24.3527 27.9964Z" fill="#000" />
                </svg>
              </Link>
              <img className="router-navbar-burger" src={burger} onClick={this.toggleMenu}/>
            </div>
          </div>
          <div className={"overlay-menu " + (this.state.menuOpen ? "show" : "")}>
            <div className='overlay-cross-container'><img src={cross} onClick={this.toggleMenu}/></div>
            <div className="overlay-links">
              <NavLink exact to="/" activeClassName={activeClassName} className={"overlay-link" + this.isActive('/')}>home</NavLink>
              <NavLink exact to="/work" activeClassName={activeClassName} className={"overlay-link" + this.isActive('/work')}>work</NavLink>
              <NavLink exact to="/about" activeClassName={activeClassName} className={"overlay-link" + this.isActive('/about')}>about</NavLink>
              <a href='https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0' className="overlay-link">resume</a>
            </div>
          </div>
          <div className={"overlay-bg " + (this.state.menuOpen ? "show-bg" : "")}/>
        </div>
      )
    } else {
      return (
        <div className="router-navbar-wrapper font-medium-small" id={this.colorset}>
          <div className="router-navbar-inner-wrapper">
            <Link to='/'>
              <svg className="logo" width="45" height="55" viewBox="0 0 45 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="45" height="55" fill="transparent" />
                <path shape-rendering="auto" fill-rule="evenodd" clip-rule="evenodd" d="M39.5967 0.497098C35.3382 -1.0529 30.6294 1.14284 29.0794 5.40142L24.464 18.082C23.8612 18.0227 23.2499 17.9923 22.6314 17.9923C21.9256 17.9923 21.2291 18.0319 20.5438 18.1089L15.9188 5.40167C14.3688 1.14309 9.66001 -1.05265 5.40143 0.497343C1.14285 2.04734 -1.0529 6.75612 0.497097 11.0147L6.48453 27.465C4.99112 30.133 4.1398 33.2091 4.1398 36.484C4.1398 46.6966 12.4188 54.9756 22.6314 54.9756C32.8441 54.9756 41.1231 46.6966 41.1231 36.484C41.1231 33.0926 40.2101 29.9145 38.6166 27.182L44.5011 11.0145C46.0511 6.75588 43.8553 2.04709 39.5967 0.497098ZM24.3527 27.9964C23.663 26.3718 21.3605 26.3718 20.6708 27.9964L19.0905 31.7185C18.9221 32.1152 18.6299 32.447 18.2576 32.6642L14.3966 34.9175C13.074 35.6894 13.074 37.6004 14.3966 38.3723L18.2576 40.6255C18.6299 40.8428 18.9221 41.1745 19.0905 41.5713L20.6708 45.2934C21.3605 46.9179 23.663 46.9179 24.3527 45.2934L25.933 41.5713C26.1014 41.1745 26.3936 40.8428 26.7659 40.6255L30.6269 38.3723C31.9495 37.6004 31.9495 35.6894 30.6269 34.9175L26.7659 32.6642C26.3936 32.447 26.1014 32.1152 25.933 31.7185L24.3527 27.9964Z" fill="#000" />
              </svg>
            </Link>
            <div className={"router-navbar"}>
              <ScreeningButton onClick={()=>{}} color="pink" >
                <NavLink exact to="/" activeClassName={activeClassName}>HOME</NavLink>
              </ScreeningButton>
              <ScreeningButton onClick={()=>{}} color="pink">
                <NavLink exact to="/work" activeClassName={activeClassName}>WORK</NavLink>
              </ScreeningButton>
              <ScreeningButton onClick={()=>{}} color="pink">
                <NavLink exact to="/about"activeClassName={activeClassName}>ABOUT</NavLink>
              </ScreeningButton>
              <ScreeningButton onClick={()=>{}} color="pink">
                <a href='https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0' target="_blank">RESUME</a>
              </ScreeningButton>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default HeaderBar;