// libraries
import React from 'react';
import AnimateHeight from 'react-animate-height';
import CryptoJS from 'crypto-js'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './PasswordComponent.css'

class PasswordComponent extends React.Component {
  constructor(props) {
    super(props)
    this.children = props.children;
    this.sha256 = this.props.sha256;
    this.state = {
      text: '',
      isUnlocked: false, 
      height: 0, 
      sectionHeight: 300, 
      fade: false }
  }

  onTextChange = (event) => {
    const text = event.target.value.replace(/ /g, '');
    this.setState({text: text});
  }

  unlock = () => {
    let hash = CryptoJS.SHA256(this.state.text).toString()
    if(hash == this.sha256){
      this.setState({ isUnlocked: true, height: 'auto', sectionHeight: 0, fade: true});
    }
  }

  handleKeyDown = (event) => {
    if(event.key == 'Enter'){
      this.unlock();
    }
  }
      
  render() {
    const isUnlocked = this.state.isUnlocked;
    const fade = this.state.fade;
    return (
        <div>
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <AnimateHeight
            duration={1000}
            height={this.state.sectionHeight}
          >
            <br/><br/><br/>
            <div className={isUnlocked ? 'hide-password' : ''}>
              <h3 style={{textAlign: 'center', fontWeight:'500'}} className={'h3'}>Sorry! This page is password protected.</h3>
              <p style={{textAlign: 'center'}} className={'p'}>Please contact me at mira.ram(@)gmail(.)com for access.</p>
              <br></br>
              <div className={'field-container'}>
                <input 
                  className={'field'}
                  type="password" 
                  placeholder='Type password here' 
                  value={this.state.text} 
                  onChange={this.onTextChange} 
                  onSubmit={this.unlock} 
                  onKeyDown={this.handleKeyDown} />
                <button className={'submit-button'} onClick={this.unlock} style={{backgroundColor: this.props.color}} >Submit</button>
              </div>
              
            </div>
          </AnimateHeight>
          <AnimateHeight
            duration={1000}
            height={this.state.height}
            onAnimationEnd={() => this.setState({ fade: false })}
            className={fade ? 'fade' : ''}
          >
            {isUnlocked && this.children}
          </AnimateHeight>
        </div>
    );
  }
}

export default PasswordComponent;
