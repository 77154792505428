// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ImageScrollList from './ProjectDetailPageComponents/ImageScrollList';

import ScrollButton from '../../Components/ScrollButton';

// assets
// Import your Figma case study assets here
// Example:
// import banner from '../../assets/figma/figma_banner.png';
import floorDwellingMira from '../../assets/landing_page_2/floor_dwelling_mira.png';

// Add imports for case study images/videos
import campfireSample from '../../assets/coverletters/campfire_sample.png'; 
import netxdSample from '../../assets/coverletters/netxd_sample.png'; 
import flourishSample from '../../assets/coverletters/flourish_sample.png'; 
import unmaskedSample from '../../assets/coverletters/unmasked_sample.png'; 
import share_quest from '../../assets/campfire_collab/share_quest.mp4';
import figma_drawings from '../../assets/coverletters/figma_drawings.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css';
import '../../Styles/coverletter.scss';
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';

class FigmaCoverLetterPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    // Replace with your Figma banner when available
    // this.background = banner;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#ed8f8a' // Pink from About page links
    
    // Adding custom styles for the video container
    this.videoStyle = {
      width: '100%',
      height: '100%', // Fixed height to match other containers
      minHeight: '400px',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: '8px'
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div key={index} className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        {/* <div className={'project-detail-page-banner'} style={{backgroundColor: '#F1F1F1', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} /> */}
        <br/><br/><br/><br/><br/>
        <div className='project-detail-page-layout'>
          {/* ---------- Title ---------- */}
          {/* ---------- Password ---------- */}
          
   
          <PasswordComponent
            sha256={'444b8898ddff6ce3e121c17b3053b33e1f7658fa1d7ba5f2bcb25c61c2a235c6'}
            color={'#39271C'}>   
          
            
          <h1 className="h1">Figma Cover Letter</h1>
          <h2 className="h2 subtitle">
            Why I'd love to shape the future of FigJam as a Product Designer
          </h2>
          <br/>

          {/* ---------- About ---------- */}
          <h2 className='h2'>About</h2>
          <p className='p'>
            Thanks for stopping by! I'm Mira, a NYC-based UX designer with <b className="text-emphasis">7+ years of experience</b> designing <b className="text-emphasis">0-to-1 solutions</b> for clients ranging from <b className="text-emphasis">Y Combinator-backed startups to Meta</b>. My work has been featured at <b className="text-emphasis">Sundance Film Festival, ABC News, and PBS</b>. I currently lead the design of enterprise 3D collaboration software at <a href="https://campfire3d.com/" target="_blank" rel="noopener noreferrer" style={{color: this.accent_color, fontWeight: 600}}>Campfire</a>, where I create <b className="text-emphasis">multi-platform, research-driven solutions</b> that balance innovation with usability. I specialize in <b className="text-emphasis">business-focused design strategy</b> rooted in thorough research and brought to life with engaging visual interfaces.
          </p> <br/>
          <div className="center-content">
            <img 
              src={floorDwellingMira} 
              alt="Mira illustration" 
              className="profile-image"
            />
          </div>
          <br/>

          {/* ---------- Why Figma? ---------- */}
          <div className="callout-section" style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }}>
            <div className="callout-container" style={{ background: 'transparent', boxShadow: 'none', padding: '0', marginLeft: 0 }}>
              <div className="callout-text left-aligned" style={{ marginLeft: 0, paddingLeft: 0, flex: '1' }}>
                <h2 className='h2'>Why Figma?</h2>
                <p className='p'>
                  <b className="text-heading">I literally live in Figma</b><br/>
                  I've been a <b className="text-emphasis">power user of Figma since 2017</b>, and I really can't imagine designing in anything else. Beyond traditional UX workflows, I use Figma for all sorts of unconventional things: trip planning, book layout, vector animation (like the ones on my home page), and even drawing anime-style vector art. <b className="text-emphasis">Figma is both my workspace and creative playground.</b>
                </p><br/>
                
                <p className='p'>
                  What excites me most about Figma is how it has revolutionized the way teams collaborate. Through my experience designing enterprise collaboration tools, I've seen firsthand how the right features can transform team dynamics and workflows. I'm particularly drawn to FigJam's focus on <b className="text-emphasis">making collaborative ideation accessible and enjoyable for everyone</b>, regardless of their role or design experience.
                </p><br/>
                
                <p className='p'>
                  <b className="text-heading">Why FigJam?</b><br/>
                  I'm excited about the opportunity to expand and iterate on the functionality for a multi-user collaborative tool like FigJam.  <b className="text-emphasis">Part of what sets FigJam apart from Figma's core product is that its target users aren't just designers</b> - it's also for Product Managers, Engineers, Marketing teams, and anyone who needs to think visually with others. Through my work at Campfire, I've pioneered new interaction patterns for 3D design review and training across diverse stakeholders, many with no prior 3D experience. <b className="text-emphasis">Creating simple but robust tools that work for all kinds of users is a challenge I know well—and one I'd love to keep solving with FigJam.</b>
                </p>
              </div>
              <div className="callout-image right-aligned" style={{ flex: '1' }}>
                <div className="image-container" style={{ 
                  maxWidth: "600px", 
                  marginLeft: "20px",
                  marginTop:"60px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}>
                  <img 
                    src={figma_drawings} 
                    alt="Figma design drawings" 
                    style={{ 
                      borderRadius: '25px', 
                      width: '100%', 
                      height: 'auto', 
                      objectFit: 'contain'
                    }}
                  />
                  {/* <figcaption style={{ fontSize: "0.8em", color: "#888", textAlign: "center", marginTop: "8px" }}>
                    Yup, it's all Figma
                  </figcaption> */}
                </div>
              </div>
            </div>
          </div>
          <br/>

          {/* ---------- Why I'm a Strong Fit ---------- */}
          <h2 className='h2'>Why I'm a strong fit</h2> 
          
          {/* Product Design & Strategic Development - Section 1 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image left-aligned">
                <div className="image-container">
                  <video 
                    src={share_quest}
                    alt="Campfire share quest functionality" 
                    controls
                    autoPlay
                    muted
                    loop
                    className="video-element"
                    style={this.videoStyle}
                  ></video>
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>01.</span> Product design & strategic development
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Led the redesign of Campfire's collaboration tools, creating intuitive interfaces that drove a <b className="text-emphasis">98% increase in collaborative tool usage</b> and helped secure contracts with Fortune 500 companies including Coca-Cola, PepsiCo, and Whirlpool.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Spearheaded Unmasked's redesign as Head of Product, managing a cross-disciplinary team that grew the platform to <b className="text-emphasis">10K+ users across 45+ universities</b>, with features in ABC News and the Washington Post.
                      <div className="case-study-button">
                        <a href="/unmasked" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-purple">
                            View Unmasked
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> My experience delivering high-quality collaboration tools and driving significant user adoption aligns with Figma's need for a strategic product designer who can shape FigJam's vision while shipping ambitious improvements.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* User-Centered Innovation - Section 2 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>02.</span> User-centered innovation
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Published research on XR social interaction with Meta Research and Dartmouth, developing methodologies for evaluating user experience in collaborative environments.
                      <div className="case-study-button">
                        <a href="/silvr" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-blue">
                            View Meta Quest Research
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Created Campfire's "Spark" onboarding experience, an innovative AI-guided tutorial that drove <b className="text-emphasis">62% of users to engage in multiuser sessions</b>, up from just 9% previously.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> My research background and focus on data-driven improvements demonstrates my ability to explore concepts thoroughly and design simple solutions to complex user problems.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image right-aligned">
                <div className="image-container">
                  <img 
                    src={campfireSample} 
                    alt="Campfire collaboration platform" 
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Design Leadership & Mentorship - Section 3 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image left-aligned">
                <div className="image-container">
                  <img 
                    src={flourishSample} 
                    alt="Flourish health platform interface" 
                  />
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>03.</span> Design leadership & mentorship
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Led UX for <b className="text-emphasis">11 early-stage startups</b> at DALI Lab, shipping products while developing collaboration frameworks for a <b className="text-emphasis">70+ member organization</b>.
                      <div className="case-study-button">
                        <a href="/flourish" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-light-blue">
                            View Flourish
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Lead design for Campfire's cross-functional team, collaborating with <b className="text-emphasis">C-suite executives</b> on product vision while managing junior designers and developers to deliver cohesive solutions.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> My experience leading cross-functional teams and mentoring designers positions me well to contribute to Figma's collaborative culture and help others grow while delivering impactful work.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* ---------- Summary ---------- */}
          <br/>
          <h2 className='h2'>Summary</h2>
          <p className='p'>
            FigJam represents everything I love about Figma's approach to collaboration - taking complex workflows and making them accessible and enjoyable for everyone. Through my experience designing enterprise collaboration tools, I've developed a deep appreciation for the challenge of creating simple yet powerful features that work for diverse teams. I'm excited about the opportunity to bring this perspective to FigJam, helping shape tools that make visual collaboration feel as natural as working together in person.
          </p>
          
          <p className='p'>
          Thank you for your time and consideration. I look forward to hearing back!
          </p>
          
          {/* ---------- Contact Info ---------- */}
          <div className="contact-section">
            <div className="contact-container">
              <div className="contact-links">
                <a href="https://www.linkedin.com/in/miraram/" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>LinkedIn</a>
                <span className="separator">|</span>
                <a href="https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>Resume</a>
                <span className="separator">|</span>
                <a href="mailto:mira.ram@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>mira.ram@gmail.com</a>
                <span className="separator">|</span>
                <span className="phone">(785) 304-3812</span>
              </div>
            </div>
          </div>
          
          
          </PasswordComponent>
          
        </div>
        <Footer />
      </div>
    );
  }
}

export default FigmaCoverLetterPage; 