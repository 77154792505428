// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ScrollButton from '../../Components/ScrollButton';

// assets
import campfire_modes from '../../assets/campfire/campfire_modes.png';
import comment_panel from '../../assets/campfire/comment_panel.png';
import comment_panel1 from '../../assets/campfire/comment_panel 1.png';
import concept_sketches from '../../assets/campfire/concept_sketches.png';
import dynamic_scaling from '../../assets/campfire/dynamic_scaling.png';
import early_iterations from '../../assets/campfire/early_iterations.png';
import early_iterations1 from '../../assets/campfire/early_iterations1.png';
import new_commenting from '../../assets/campfire/new_commeting.png';
import old_commenting from '../../assets/campfire/old_commeting.png';
import pain_points from '../../assets/campfire/paint_points.png';
import user_personas from '../../assets/campfire/user_personas.png';
import aud_comments from '../../assets/campfire/aud_comments.png';
import aud_sketching from '../../assets/campfire/aud_sketching.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';


class CampfireProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/campfire_banner3.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#F84536'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Campfire <span style={{opacity: '90%', fontWeight: 400}}>- Annotations </span></h1>
          <h2 className="h2">
          <a target="_blank" href="https://www.campfire3d.com/" style={{color: this.accent_color}}> Campfire</a>  is an enterprise 3D collaboration software, and the best way to communicate complex concepts in design reviews, training, and more. Campfire is <a target="_blank" href="https://www.campfire3d.com/" style={{color: this.accent_color}}>available</a> for PC, Mac, iPad, and soon Meta Quest.

          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX", "XR", "3D", "Graphic Design"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          When I joined Campfire as a UX designer, the software was in an early access phase with a handful of enterprise customers. During my initial months with the company, I had the opportunity to engage with these early customers and other interested parties to gather insights on their intended use cases. These conversations were instrumental in honing our understanding of specific user needs.
          <br/><br/>
          This case study details my efforts to evolve our existing annotation tools, and how the implementation of these new designs led to a customer success story.

          </p>

          <PasswordComponent
        sha256={'fc97013f69b1cbc4d4b26220fb8903991343024fd159fc6013974eda2a09d2c5'}
        color={this.accent_color}>

          {/* Graphical list idk */}
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["November 2022 – March 2023 \n(4 mo)",
                         "Lead Designer",
                         "Rapid Iteration\nWireframing\nPrototyping\nUser Research\nUser Interviewing\nFigma\nUnity\nJira",
                         "Mira Ram\nAlex Turin (Product Architect)"]}
          />



          {/* Customer Needs */}
          <h2 className='h2'>Customer Needs</h2>
          <p className='p'>
          Design review has always been a core use case for Campfire, which aims to provide simple tools for communicating complex concepts. Feedback is a key part of design reviews. Campfire provides two simple methods of doing so through <b style={{color: this.accent_color}}>annotations</b>: text <b style={{color: this.accent_color}}>comments</b> for writing lengthy feedback and 2D <b style={{color: this.accent_color}}>sketches</b> when visuals are needed.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={campfire_modes} src={campfire_modes} />
          </div>

          <p className='p'>
          Annotations in Campfire have existed since the software's very earliest concepts -- far before I joined the team. However, as we expanded to enterprise customers through our early access program, we noticed that employees weren't utilizing its features. To gain a better understanding, we met with customer teams (primarily at a leading American sneaker company) composed of desktop and iPad users who did not have the Campfire Headset. In these meetings, we observed how they used Campfire and asked them to demonstrate their design review processes with other methods or software.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={old_commenting} src={old_commenting} />
          </div>

          <h3 className='h3'>Initial Insights: Pain Points</h3>
          <p className='p'>
          At the time, it was only possible to create, share, edit, and resolve annotations while in iPad AR mode, or by using our custom Campfire Headset. This meant that users would not be able to comment at all if they (1) did not have an iPad or (2) were not in an appropriate area to switch their iPad app in to AR mode and maneuver the camera to make an annotation.
          <br/><br/>
          Additionally, some users weren't aware that the annotation tool was nested within the AR mode toolset. Even those familiar with AR mode sometimes struggled with the annotation tool's operation, particularly during the "drag and release" stage. We began to wonder if the annotation creation process involved too many steps: opening a project, activating AR mode, scanning the area for 3D content placement, and then finally switching to the annotation tool – a multi-step process in itself.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={pain_points} src={pain_points} />
          </div> <br/>

          <h3 className='h3'>Initial Insights: Requested Features</h3>
          <p className='p'>
          Customers also expressed that the software was not meeting their needs in other ways. We received numerous feature requests during these meetings and were able to categorize customer needs into 3 basic user personas: <b style={{color: this.accent_color}}>The Reviewer</b>, <b style={{color: this.accent_color}}>The Designer</b>, and <b style={{color: this.accent_color}}>The Observer Manager</b>.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={user_personas} src={user_personas} />
          </div>

          <br/><br/>
          <h3 className='h3'>Initial Insights: Summary</h3>
          <p className='p'>
          From these customer meetings, we were able to gain four key insights that would inform further iterations on annotations:
          </p>


          <p className='p'>
          <b style={{fontWeight:600}} className='b'> <span style={{color: this.accent_color, fontWeight:500}}>#01: </span>Access to annotation tools is limited and difficult to find </b>
          </p>

          <p className='p'>
          <ul className='ul'>
            <li className='li'>Annotations could only be created, shared, edited, and resolved in iPad AR mode or on the Campfire Headset, making access limited.</li>
            <li className='li'>The process of switching to AR mode and using the annotation tool proved overly complex for users.</li>
          </ul>
          </p>



          <p className='p'>
          <b style={{fontWeight:600}} className='b'> <span style={{color: this.accent_color, fontWeight:500}}>#02: </span>The current annotation tool is not intuitive  </b>
          </p>

          <p className='p'>
          <ul className='ul'>
            <li className='li'>After writing a comment and pressing enter, users were confused by the instructions to place their comment.</li>
            <li className='li'>If users were not in a location where it would be easy for them to maneuver around the model, creating or managing annotations could be difficult.</li>
          </ul>
          </p>



          <p className='p'>
          <b style={{fontWeight:600}} className='b'> <span style={{color: this.accent_color, fontWeight:500}}>#03: </span>Too many annotations in the scene obstruct the view  </b>
          </p>

          <p className='p'>
          <ul className='ul'>
            <li className='li'>Observers needed to be able to view the model without comments getting in the way.</li>
          </ul>
          </p>



          <p className='p'>
          <b style={{fontWeight:600}} className='b'> <span style={{color: this.accent_color, fontWeight:500}}>#04: </span>Campfire annotations aren't report-friendly  </b>
          </p>

          <p className='p'>
          <ul className='ul'>
            <li className='li'>Managers needed a straightforward way to generate reports containing all annotations from design review sessions.</li>
            <li className='li'>Designers needed to be able to search and reference specific annotations within a scene.</li>
          </ul>
          </p>
          <br/>



          <ProjectAnnouncementComponent
            color = {this.accent_color}
            title = "THE GOAL"
            text = {`How might we make reviewing 3D data as frictionless as possible?`}
          />

          {/* Ideation */}
          <h2 className='h2'>Ideation</h2>
          <h3 className='h3'>Brainstorming & Sketching</h3>
          <p className='p'>
          Based on our insights, I began sketching ideas for a new version of the annotation tool.
          <br/><br/>
          To address <b>Insight #1</b> (limited access and tool visibility), I proposed a design where users could create, edit, share, and resolve annotations in audience mode. The challenge with this idea would be ensuring a consistent user experience between audience and AR modes.
          <br/><br/>
          In response to <b>Insight #2</b> (lack of intuitiveness in the current tool), I proposed dividing the annotation tool into two separate tools: "comments" and "sketches." I also considered further refinements to simplify the mechanics of these tools.
          <br/><br/>
          Finally, to tackle <b>Insights #3 and #4</b> (annotations obstructing the view and lacking report-friendly features), I proposed the implementation of a comment panel. This panel would enable users to reference all annotations within a project, search for specific annotations, export all annotations, and toggle the visibility of annotations within the view.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={concept_sketches} src={concept_sketches} />
          </div>

          <h3 className='h3'>Early Iterations</h3>
          <p className='p'>
          In my early design iterations that incorporated the brainstormed ideas, I delved into various representations of comments and methods for dragging out comments that would closely align with the experience in AR mode. This might include using a similar laser pointer or dragging on the model directly. I also had to consider that comments could only be dragged on two axes instead of the three granted in AR mode.
          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={early_iterations} src={early_iterations} />
          </div>
          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={early_iterations1} src={early_iterations1} />
          </div>
          During this exploration, we ended up fundamentally changing the process of creating a text comment from [write, point, drag] to [point, drag, write]. This change not only facilitated consistency between audience and AR mode tools, but also had the beneficial effect of front-loading the "point and drag" actions, thereby enhancing the clarity of each step's purpose. Furthermore, this change allowed for the creation of a placeholder comment with a busy signal of sorts to indicate to other users in the scene that a particular user was in the process of writing a comment. This process change was not necessary for sketch because sketches are placed at the location the snapshot is taken, with a placeholder sketch icon appearing until the sketch is completed or deleted.
          </p>

          <br/>
          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={new_commenting} src={new_commenting} />
          </div>


          <br/>
          <h3 className='h3'>Customer Feedback</h3>
          <p className='p'>
          We presented several of my designs to our initial set of customers to gather feedback and assess their readiness for implementation. Among these designs, the audience mode annotations and comment panel received particularly positive responses from customers. This encouraged us to continue in the direction we were already pursuing.
          </p><br/>


          {/* Final Product */}

          <h2 className='h2'>Final Product</h2>
          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/6To5hILFPoc"
            title="Annotations Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          <br/><br/>
          <p className='p'>
          After creating <b>100+ screens over four rounds of iterations</b> with changes informed by internal review and developer concerns, I landed on final set of designs that I would hand off to our developers for implementation.
          <br/><br/>
          Above is the live implementation of these designs (with the exception of the comment panel which is set to be implemented in a future release).
          </p>
          <br/>


          <h3 className='h3'>Audience Mode Annotations - <a target="_blank" href="https://www.figma.com/file/bTxadz8s4OIC7zHM41aGKX/Campfire-Annotations?type=design&node-id=0%3A1&mode=design&t=r9hpPHOfaM177Er9-1" style={{color:this.accent_color}}>Figma</a></h3>
          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={aud_comments} src={aud_comments} />
          </div>
          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={aud_sketching} src={aud_sketching} />
          </div>
          <p className='p'>
          The most significant change was the introduction of the quick access toolbar in the bottom right corner. This addition finally allowed users to comment and sketch on desktop, while streamlining the process on iPad. It also introduced the laser tool to audience mode, enabling users to easily point out parts of a model.
          <br/><br/>
          We also gave the users the ability to resolve, share, and edit sketches directly in audience mode by clicking on comments. This behavior would be consistent with the action used to select parts of the model.
          </p><br/>


          <h3 className='h3'>Comment Panel - <a target="_blank" href="https://www.figma.com/file/bTxadz8s4OIC7zHM41aGKX/Campfire-Annotations?type=design&node-id=1%3A26033&mode=design&t=r9hpPHOfaM177Er9-1" style={{color:this.accent_color}}>Figma</a> </h3>
          {/* <iframe style={{border: '1px solid rgba(0, 0, 0, 0.1)', width:'100%',minHeight:'500px'}} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FbTxadz8s4OIC7zHM41aGKX%2FCampfire-Annotations%3Fpage-id%3D1%253A26033%26type%3Ddesign%26node-id%3D1-47048%26viewport%3D513%252C1061%252C0.05%26t%3DUmL10b4ZgkQMcwVI-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D1%253A47048%26mode%3Ddesign" allowfullscreen></iframe> */}

          <p className='p'>
          The comment panel was a significant feature addition to the software, undergoing more iterations than any other feature listed. It was challenging to integrate it into the app without cluttering the UI, keeping in mind existing menus that could close and expand. Initially, the comment panel was designed as a semi-permanent menu located on the right side of the screen, similar to the inspector, scenes list, and hierarchy. However, in the end, we opted for an overlay approach to reduce clutter and align with conventions followed by commonly used 2D applications like Google Docs and Miro.
          <br/><br/>
          We also aimed to group icons by their intended usage. For example, all buttons related to uploading models were placed together, so it made most sense to place the comment panel toggle in close proximity to "social" UI elements like the user list and share button for a more cohesive user experience.
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={comment_panel} src={comment_panel} />
          </div>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={comment_panel1} src={comment_panel1} />
          </div>
          One of the greatest challenges we faced was determining how to represent comments and sketches within the comment panel. We had future plans to implement the ability to reply to comments, but to adhere to development constraints, we decided to omit replies in the first version of the design with the intention of adding them back later.
          <br/><br/>
          Additional features that were integrated into the comment panel included the ability to write comments directly within the panel, hide/show all comments in view, search, apply filters, and export a report of all annotations (with several iterations dedicated to refining the PDF layout). It was crucial for these features to operate in a cohesive manner, mirroring the user experience of similar functions found elsewhere in the app (e.g., the search and filter mechanisms should closely resemble those used in the project list to maintain consistency).
          </p><br/>

          <h3 className='h3'>Considering "Edge" Cases - <a target="_blank" href="https://www.figma.com/file/bTxadz8s4OIC7zHM41aGKX/Campfire-Annotations?type=design&node-id=1%3A20397&mode=design&t=r9hpPHOfaM177Er9-1" style={{color:this.accent_color}}>Figma</a></h3>
          <p className='p'>
          {/* <iframe style={{border: '1px solid rgba(0, 0, 0, 0.1)', width:'100%',height:'300px' }} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FbTxadz8s4OIC7zHM41aGKX%2FCampfire-Annotations%3Ftype%3Ddesign%26node-id%3D1%253A20397%26mode%3Ddesign%26t%3Dr9hpPHOfaM177Er9-1" allowfullscreen></iframe> */}
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={new_commenting} src={new_commenting} />
          </div>
          In Campfire, users can take on the role of a "presenter." Presenters have special privileges, including the ability to switch scenes and use additional tools for interacting with and manipulating models. Typically, presenters lead design review presentations or training sessions.
          <br/><br/>
          One unique situation we encountered was that users might be in the process of creating an annotation one scene when the presenter switches scenes, potentially causing the user's annotation to be lost. To address this complex issue, I worked with the developers to create a detailed decision tree. One significant change we made was to alter the process of creating a text comment from [write, point, drag] to [point, drag, write]. This adjustment not only improved the tool's overall usability but also reduced the impact of interruptions during the dragging phase.

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={dynamic_scaling} src={dynamic_scaling} />
          </div>
          Another edge case became apparent when we introduced selectable comments: the challenge of reading and interacting with annotations located far away in large models. While the comment panel offered some assistance, the inability to read most comments in a large project would render the model's visualization almost useless. A potential solution to this issue involved dynamically scaling comments based on their distance from the viewport to enhance readability and usability.

          </p>
          <h2 className='h2'>Challenges & Takeaways</h2>
          <p className='p'>
          <b style={{fontWeight:600}}>Scoping down designs for development prioritization  </b> <br/>
          When creating these designs, the goal was to meet as many user needs as possible. However, given the large number of feature changes proposed, it would be unrealistic to include all of them in one update given developer time constraints. Part of the challenge was scoping down parts of the designs that could not be immediately implemented, creating steps towards the final product that could be reached in phases.
          </p>
{/* 
          <p className='p'>
          <b style={{fontWeight:600}}>Rapid Iteration without Formal User Testing </b> <br/>
          In my previous design roles, we had the luxury of allocating substantial time to formal user research and testing sessions involving external users during iterations. However, the process was much different when gathering early feedback from customers for this project. Additionally,  much of the feedback between iterations came from internal sources. Even so, I was able to extract valuable insights from the feedback received from customers and team members alike.
          </p> */}

          <p className='p'>
          <b style={{fontWeight:600}}>Designing for Multiple Devices  </b> <br/>
          One of Campfire's biggest selling points is its cross-device functionality, making it essential for my designs to seamlessly accommodate different devices, screen sizes, and user input methods, including touch screens and mouse controls.

          </p>

          <p className='p'>
          <b style={{fontWeight:600}}>Avoiding feature-creep   </b> <br/>
          Balancing the incorporation of new features without overwhelming the user interface was a significant  challenge. To maintain the app's simplicity and avoid feature-creep, it was important to resist the temptation to add too many features simply because users requested them.

          </p>



          <h2 className='h2'>Post-Launch & Next Steps</h2>
          <p className='p'>
          After launching the new annotation features in our app, we continued to present our app to potential clients, partners, and investors. During one of these presentations, <b style={{color: this.accent_color}}>Coca Cola </b>recognized the potential of our audience annotations as labels for components during training sessions for engineers working on dispenser machines. The company has since embedded Campfire into their training process.</p>
          <p className='p'>
          As our interactions with customers continue to grow, and our company evolves, we anticipate the expansion of our feature set. Features that have not yet been implemented will gradually find their place in the product as we progress along our product roadmap.</p>
          <p className='p'>
          You can request an invite to try Campfire at <a target="_blank" href="https://www.campfire3d.com/" style={{color: this.accent_color}}> campfire3d.com</a>
          </p>


          <p></p>
          </PasswordComponent>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default CampfireProjectPage;
