import React, { useState, useRef, useEffect } from 'react';
import './ImageScrollList.css';

const ImageScrollList = ({ children }) => {
  const [zoomedImage, setZoomedImage] = useState(null);
  const [hiddenIndex, setHiddenIndex] = useState(null);
  const scrollRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        setCanScrollLeft(scrollRef.current.scrollLeft > 0);
        setCanScrollRight(
          scrollRef.current.scrollLeft < scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        );
      }
    };
    
    checkScroll();
    scrollRef.current?.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    return () => {
      scrollRef.current?.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: direction * 300, behavior: 'smooth' });
    }
  };

  const handleZoom = (index, src) => {
    setHiddenIndex(index);
    setZoomedImage(src);
  };

  return (
    <>
      <div className="scroll-container" ref={scrollRef}>
        {React.Children.map(children, (child, index) => (
          <div 
            key={index} 
            style={{ 
              display: 'inline-block', 
              marginRight: '10px', 
              pointerEvents: 'auto', 
              height: 'auto',
              width: 'auto',
              flexShrink: 0,
              cursor: 'zoom-in',
              opacity: hiddenIndex === index ? 0 : 1, // Hide the image when zoomed
              transition: 'opacity 0s ease-in-out'
            }}
            onClick={() => handleZoom(index, child.props.src)}
          >
            {child}
          </div>
        ))}
      </div>

      {canScrollLeft && (
        <div className="scroll-arrow scroll-arrow-left" onClick={() => scroll(-1)}>
          <svg viewBox="0 0 24 24">
            <path d="M15 18l-6-6 6-6" />
          </svg>
        </div>
      )}

      {canScrollRight && (
        <div className="scroll-arrow scroll-arrow-right" onClick={() => scroll(1)}>
          <svg viewBox="0 0 24 24">
            <path d="M9 18l6-6-6-6" />
          </svg>
        </div>
      )}

      {zoomedImage && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            cursor: 'zoom-out',
            transition: 'opacity 0s ease-in-out'
          }}
          onClick={() => {
            setZoomedImage(null);
            setHiddenIndex(null);
          }}
        >
          <img 
            src={zoomedImage} 
            alt="Zoomed" 
            style={{ 
              maxWidth: '90vw', 
              maxHeight: '90vh', 
              objectFit: 'contain',
              borderRadius: '10px',
              transform: 'scale(0.95)',
              animation: 'zoomBounce 0.5s ease-out forwards'
            }}
          />
        </div>
      )}

      <style>
        {`
          @keyframes zoomBounce {
            0% { transform: scale(0.95); }
            40% { transform: scale(1.02); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </>
  );
};

export default ImageScrollList;
