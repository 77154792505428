// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets


// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';


class ProducePayProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/pp_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#33CE9B'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">ProducePay</h1>
          <h2 className="h2">
          <a target="_blank" href="https://www.producepay.com/" style={{color: this.accent_color}}> ProducePay</a> finances grower supply for importers of fresh produce.
          I designed and shipped a new produce <a target="_blank" href="https://insights.producepay.com/auth/register" style={{color: this.accent_color}}>marketplace</a> to connect growers and distributors.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          ProducePay is the fastest-growing provider of capital, market insight, and trade protections for growers of fresh produce.
          Ultimately, ProducePay aims to improve the grower-distributor pipeline within the agriculture industry using its online services.
          As a product design intern, I worked on improving different aspects of ProducePay's Insights platform, most notably by designing
          their new produce marketplace.

          </p>
          <PasswordComponent
        sha256={'23e92dfba8fb0c93cfba31ad2962b4e35a47054296d1d375d7f7e13e0185de7a'}
        color={this.accent_color}>

          <p></p>
          </PasswordComponent>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default ProducePayProjectPage;
