// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';


class CBWProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/cbw_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#54CFED'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">CBW Bank</h1>
          <h2 className="h2">
          <a target="_blank" href="https://cbwpayments.com/" style={{color: this.accent_color}}> CBW Bank </a>
          is a leader in payment and lending solutions, providing technology for increased efficieny.
          I led a multi-disciplinary team while designing prototypes for an upcoming mobile and web
          digital banking and merchant acquiring app.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX", "Graphic Design"])}
          </div>

           {/* Graphical list idk */}
           {/* <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June - September 2020\n(4 months)",
                         "Product Manager\nDesigner",
                         "User Research\nRapid Iteration\nScoping\nMarketing\nFigma",
                         "Mira Ram"]}
          /> */}

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          CBW Bank is a leader in payment and lending solutions, providing technology for increased efficieny.
          I have worked on various different projects and initiatives for CBW over the past two years (as of 2022).
          For this particular project, I led a multi-disciplinary team while designing prototypes for an upcoming mobile and web
          digital banking and merchant acquiring app. The project was under a demanding time constraint, and I
          served as the sole designer. As a part of
          my design work, I conducted user research and mapped out user flows and user journeys for the app.

          </p>
          <PasswordComponent
        sha256={'23e92dfba8fb0c93cfba31ad2962b4e35a47054296d1d375d7f7e13e0185de7a'}
        color={this.accent_color}>

          <p></p>
          </PasswordComponent>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default CBWProjectPage;
