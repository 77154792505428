// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ImageScrollList from './ProjectDetailPageComponents/ImageScrollList';


import ScrollButton from '../../Components/ScrollButton';

// assets
import banner from '../../assets/campfire_collab/campfire_banner.png';
import share_quest from '../../assets/campfire_collab/share_quest.mp4';
import they_dont_know_campfire from '../../assets/campfire_collab/they_dont_know_campfire.png';

import persona1 from '../../assets/campfire_collab/persona1.png';
import persona2 from '../../assets/campfire_collab/persona2.png';
import persona3 from '../../assets/campfire_collab/persona3.png';
import persona4 from '../../assets/campfire_collab/persona4.png';
import persona5 from '../../assets/campfire_collab/persona5.png';

import new_sharing from '../../assets/campfire_collab/new_sharing.png';
import old_sharing from '../../assets/campfire_collab/old_sharing.png';
import sharing_comparison from '../../assets/campfire_collab/sharing_comparison.png';
import old_vs_new_sharing from '../../assets/campfire_collab/old_vs_new_sharing.png';

import ent_sharing_1 from '../../assets/campfire_collab/ent_sharing_1.png';
import ent_sharing_2 from '../../assets/campfire_collab/ent_sharing_2.png';

import switch_tools from '../../assets/campfire_collab/switch_tools.mp4';
import toolbar_scheme from '../../assets/campfire_collab/toolbar_scheme.png';

import share_only from '../../assets/campfire_collab/share_only.mov';
import comment_panel from '../../assets/campfire_collab/comment_panel2.mov';
import desktop_tools from '../../assets/campfire_collab/desktop_tools.mov';
import desktop_tools1 from '../../assets/campfire_collab/desktop_tools1.mov';

import quest_store from '../../assets/campfire_collab/quest_store.png';
import project_list from '../../assets/campfire_collab/project_list.png';
import support_center from '../../assets/campfire_collab/support_center.png';

import spark_slice from '../../assets/campfire_collab/spark_slice.mp4';
import spark_flow from '../../assets/campfire_collab/spark_flow.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';

const personas = [
  share_quest
];


class CampfireCollabProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = banner;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#F84536'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} />
        <div className='project-detail-page-layout'>
          {/* ---------- Title ---------- */}
          <h1 className="h1">Campfire <span style={{opacity: '90%', fontWeight: 400}}>- Collaboration</span></h1>
          <h2 className="h2">
          <a target="_blank" href="https://www.campfire3d.com/" style={{color: this.accent_color}}> Campfire</a>  is an enterprise 3D software for sharing and reviewing 3D data with multiple users across different devices. The software is <a target="_blank" href="https://www.campfire3d.com/" style={{color: this.accent_color}}>available</a> on desktop, tablet, and XR devices. 

          </h2>

          {/* ---------- Tags ---------- */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX", "XR", "3D","Research"])}
          </div>

          


          {/* ---------- Summary ---------- */}
          <h2 className='h2'>Summary</h2>
          <p className='p'>
          Over the course of a year, I designed key collaborative tools and iterated in response to user feedback. This case study covers the evolution of Campfire's core collaboration tools. 
          </p>

          {/* ---------- Splash GIF / Video ---------- */}
          {/* <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={share_quest} src={share_quest} />
          </div> */}
          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:35}}>
            <source src={share_quest} type="video/mp4" />
            Your browser does not support the video tag.
          </video>


          {/* ---------- Password ---------- */}


          <PasswordComponent
        sha256={'fc97013f69b1cbc4d4b26220fb8903991343024fd159fc6013974eda2a09d2c5'}
        color={this.accent_color}>  


          {/* ---------- Deliverables and Impact ---------- */}
          <br/><br/>
          <div className='callout-container' style={{ display: 'flex', gap: '20px', flexWrap: 'wrap',marginBottom:'-15px' }}>
            <div className='callout' style={{ flex: '1', minWidth: '300px' }}>
              <h2 className='h2' style={{ marginBottom: '20px' }}>Deliverables</h2>
              <ul className='ul'>
                <li className='li' style={{ fontWeight: '400' }}>
                  Simplified project sharing experience that mimics other collaborative tools.
                </li>
                <li className='li' style={{ fontWeight: '400' }}>
                  Robust collection of simple and easy-to-use tools for 3D collaboration.
                </li>
                <li className='li' style={{ fontWeight: '400' }}>
                  Comprehensive onboarding experience that mimics human introduction to the software.
                </li>
                <li className='li' style={{ fontWeight: '400' }}>
                  Collaboration-focused marketing material.
                </li>
              </ul>
            </div>

            <div className='callout' style={{ flex: '1', minWidth: '300px' }}>
              <h2 className='h2' style={{ marginBottom: '20px' }}>Impact</h2>
              <ul className='ul'>
                <li className='li' style={{ fontWeight: '400' }}>
                  Secured new enterprise customers, including 
                  <b style={{ color: this.accent_color }}> Whirlpool</b>, 
                  <b style={{ color: this.accent_color }}> Dyson</b>, and 
                  <b style={{ color: this.accent_color }}> Coca-Cola</b> resulting in an increase in Enterprise license sales for the company.
                </li>
                <li className='li' style={{ fontWeight: '400' }}>
                <b style={{ color: this.accent_color }}>100% increase</b>  in user retention and overall increase in active users.
                </li>
                <li className='li' style={{ fontWeight: '400' }}>
                <b style={{ color: this.accent_color }}>98% increase</b>  in users trying collaborative tools .
                </li>
              </ul>
            </div>
          </div>

          


          {/* ---------- Project Details ----------*/}
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Skills", "Tools", "Team"]}
            textFields={["2023 - 2024 (across several sprints)",
                         "Rapid Iteration\nWireframing\nPrototyping\nUser Feedback\nData Analysis\nUser Research\nDesign Strategy\n Roadmapping",
                         "Figma\nUnity\nJira\nConfluence",
                         "Mira Ram (Lead Designer)\nAlex Turin (Design Manager)\nYuhan Lu (Lead Developer)"]}
          />


          {/* ---------- Background ----------*/}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          Campfire is a multi-platform software tailored for design review and training on 3D data. The company's mission is to make sharing and reviewing 3D models as simple as collaborating in 2D. 
          <br/><br/>
          When I joined the company in 2022, Campfire was still in an early-access phase and dually focused on hardware and software. After a successful pivot to software-only, we focused on unifying all tools across desktop, tablet, and XR devices. When we finally released the software to the public in early 2023, we identified a core issue upon observing our usage data: <b >users didn't understand that Campfire is meant for collaboration. </b>
          </p>  

          {/* ---------- THE PROBLEM ---------- */}
          <ProjectAnnouncementComponent
            color = {this.accent_color}
            title = "BUSINESS PROBLEM"
            text = {`Users didn't understand that Campfire is meant for collaboration.`}
          />

          {/* ---------- FUNNY MEME ---------- */}
          {/* <div style={{maxHeight:'80%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <img 
              key={they_dont_know_campfire} 
              src={they_dont_know_campfire} 
              style={{height:'40vh', minHeight:'250px',display:'flex', alignItems:'center', justifyContent:'center'}}
              />
          </div>
          <br/> */}


          <p className='p'>
          Obviously, this was a huge problem for the business.          
          <br/><br/>

          Whenever we demonstrated the software at live events, users would instantly understand Campfire's core use cases and values. It was surprising to find that users were not discovering collaborative features when left to their own devices.
          <br/><br/>
          
          Through analyzing our usage data to understand why this might be the case, we identified key areas for improvement based on user pain points: 

          <br/><br/>


          {/* ---------- Sharing ---------- */}
          <div className='callout'>
            <p className='p'>
            <b>Sharing</b>
            <br></br>
            We found that only 9% of users were sharing projects and entering multiuser sessions, the first step to collaborating with anyone at all. 
            </p>
            <b style={{fontWeight:400}}> <span style={{fontWeight:600, color: this.accent_color}}>Objective #1:</span> Users need an easy way to share projects with others to collaborate on 3D data</b>
          </div>

          {/* -------- Collaborative Tools -------- */}      
          <div className='callout'>
            <p className='p'>
            <b>Collaborative Tools</b>
            <br/>
            Only 2% of users were using collaborative tools after entering a collaborative multiuser session.
            </p>
            <b style={{fontWeight:400}}> <span style={{fontWeight:600, color: this.accent_color}}>Objective #2: </span>Collaborative tools should be intuitive and easy to use </b>
          </div>

          {/* -------- Onboarding and Support -------- */}   
          <div className='callout'>
            <p className='p'>
            <b>Onboarding and Support</b>
            <br/>
            We often received feedback via email and app store reviews asking for tutorials and support articles on using certain features. At launch, we also had no in-app onboarding experience 
            </p>
            <b style={{fontWeight:400}}> <span style={{fontWeight:600, color: this.accent_color}}>Objective #3:</span> Users need intuitive, easily accessible guidance on how to use the app, even with its self-explanatory design  </b>
          </div>

          </p>

          <div className="divider"></div>

          {/* Approach */}
          <h2 className='h2'>Approach</h2>
          

          {/* ---------- Product Strategy ---------- */}
          <h3 className='h3'>Product Strategy</h3>
          <p className='p'>
          When defining an approach for solving these problems, we categorized potential solutions into two buckets: <div class="tag">Table Stakes</div> and <div class="tag" style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiators</div>.

          <br/><br/>
          <b>Table Stakes</b> 
          <br/>
          Campfire is uniquely positioned as a 3D software aimed at providing the simplicity of familiar 2D software. We were missing several key features and UX patterns that users might expect from 2D collaborative tools. This included the functionality of our sharing interface, comprehensive annotation management, and a user onboarding experience.
          <br/><br/>

          <div className='callout'>
          <b style={{fontWeight:400}}>How might we incorporate familiar user patterns while still tailoring them to Campfire's targeted use cases?</b>
          </div>


         
          <b>Differentiators</b> 
          <br/>
          Campfire's simple collaborative tools for interacting with 3D models are part of what set us apart from old, legacy Enterprise 3D software. When designing novel features, our challenge is to maintain this simplicity while ensuring our features work consistently across desktop, tablet, and XR platforms.
          <br/><br/>

          <div className='callout'>
            
          <b style={{fontWeight:400}}>How might we create powerful collaborative tools that remain discoverable and intuitive across multiple platforms?</b>
          </div>
          
          
          </p>  
          
          <br/>

          {/* ---------- User Personas ---------- */}
          <h3 className='h3'>User Personas</h3>
          <p className='p'>
          Campfire has three primary use cases for its software: <b style={{fontWeight:500}}>Design Review</b>, <b style={{fontWeight:500}}>Technical Training</b>, and <b style={{fontWeight:500}}>Technical Sales</b>. With each of the new features we designed, we focused on our broader use cases and personas while responding to specific customer user stories.
          </p>

          <p className='p'>
          <ImageScrollList>
            <img src={persona1} alt="User Persona" />
            <img src={persona2} alt="User Persona" />
            <img src={persona3} alt="User Persona" />
            <img src={persona4} alt="User Persona" />
            <img src={persona5} alt="User Persona" />
          </ImageScrollList>
          </p>

          <p className='p'>
          With each of the new features we designed, we kept our broader use cases and personas in mind while responding to specific customer user stories. 
          </p>


          <div className="divider"></div>

          {/* ---------------------------------- Objective 1 ----------------------------------  */}
          <h2 className='h2'>Objective 1 <span style={{opacity: '92%', fontWeight: 400}}>: Users need an easy way to share projects with others to collaborate on 3D data</span></h2>
          <h3 className='h3'>Problem</h3>
          <p className='p'>
          As a primarily Enterprise software, Campfire has always aimed to make the software as secure as possible. Initially, users had to create an organization and invite others before they could share any projects. 
          
          <b style={{fontWeight:500}}> It became apparent that this process was cumbersome and unsustainable when we released the app publicly and individual users began trying Campfire on the Starter (Free) Plan. </b>
          </p>

  
          <div className="callout">
            <p className='p'>
            <b>User Stories</b><br/>
            <i>As a Design Engineer at [Manufacturing Company]*, I need to quickly share CAD data with my team and external partners without requiring them to join an organization, so we can iterate faster on designs and reduce miscommunication.</i> <br/><br/>

            <i>As a Mechanical Engineer at [Manufacturing Company]*, I need to share 3D data across departments without needing to provide every member of the organization with a Campfire license.</i> <br/><br/>

            <i>As an Administrator for an engineering team at [Aersopace/Defense Company]*, I need to control access to company data and make sure data sharing is compliant with company security policies.</i> <br/><br/>

            <span style={{fontSize:'10px'}}>*Redacted company name</span>
         
            </p>
          </div>

          <p className='p'>
          Sharing a project should be <b style={{fontWeight:500}}>familiar to users familiar with 2D collaborative tools</b>, but still <b style={{fontWeight:500}}>tailored to Campfire's specific use cases</b>.
{/* 
          <br/><br/>

          <b>Initial Flow</b> */}
          {/* ---------------------------------- OLD SHARING VS NEW SHARING ----------------------------------  */}

          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={old_vs_new_sharing} src={old_vs_new_sharing} />
          </div>

          <br/><br/>

          <h3 className='h3'>Solution</h3>
          <b>Sharing Interface</b> <div class="tag">Table Stakes</div> <br/>
          <p className='p'>We restructured our sharing interface to match familiar patterns from 2D collaboration tools like Google Drive and Figma.</p>
          
          <ul className='ul'>
            <li className='li'>Users can now click "Share," enter an email, and send an invite instantly.</li>
            <li className='li'>Link-based sharing allows for view/edit permissions, reducing friction.</li>
          </ul>

          

          {/* ---------------------------------- SHARING COMPARISON ----------------------------------  */}
          
          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={sharing_comparison} src={sharing_comparison} />
          </div>

          <br/><br/>

          <b>Tailoring for 3D Collaboration and Enterprise Security</b> <div class="tag" style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiator</div>
          <p className='p'>Enterprise users still required strict access control. We introduced:</p>
          <ul className='ul'>
            <li className='li'>Granular role permissions to define who can view, edit, or comment.</li>
            <li className='li'>Admin portal for enterprise teams to manage project access at scale.</li>
          </ul>

          <p className='p'>
          <ImageScrollList>
            <img src={ent_sharing_1} alt="Enterprise Sharing" />
            <img src={ent_sharing_2} alt="Enterprise Sharing" />
          </ImageScrollList>
          </p>

          

          <br/><br/>

          <b>Final</b>
          <p className='p'>With these changes, users can now easily invite collaborators with minimal friction, while enterprise teams still maintain security and control over their data.</p>

          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:35}}>
            <source src={share_only} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          </p>

          <div className="divider"></div>

          {/* ---------------------------------- Objective 2 ----------------------------------  */}
          <h2 className='h2'>Objective 2 <span style={{opacity: '92%', fontWeight: 400}}>: Collaborative tools should be intuitive to users</span></h2>
          <h3 className='h3'>Problem</h3>
          <p className='p'>
          At launch, very few users were using Campfire's collaboration tools. This was likely due to:
          </p>
          <ul className='ul'>
            <li className='li'>Lack of onboarding or tool explanations. </li>
            <li className='li'>No sample projects to explore real-world use cases.</li>
            <li className='li'>Poor discoverability of tools in the UI.</li>
          </ul>
          <p className='p'>
          Users needed to jump into a session and start collaborating instantly—but the experience wasn't intuitive.
          </p>

  
          <div className="callout">
            <p className='p'>
            <b>User Stories</b><br/>
            <i>As a Structural Engineer at [Aerospace/Defense Company]*, I need to quickly annotate CAD designs and share those annotations, so I can provide precise feedback during design reviews without having to send files back and forth.</i> <br/><br/>

            <i>As a Technical Trainer at [Manufacturing Company]*, I need trainees to be able to engage in real-time discussions and learn with realistic 3D models.</i> <br/><br/>

            <i>As a Field Service Technician at [Automotive Company]*, I need to be able use collaborative 3D tools with little guidance, so I can troubleshoot and get remote support without struggling to navigate the tools.</i> <br/><br/>

            <span style={{fontSize:'10px'}}>*Redacted company name</span>
         
            </p>
          </div>

          
          {/* <b>Tools Overview</b> */}
          <p className='p'>
          Campfire provides several simple tools for interacting with 3D models. Given that one of Campfire's major use cases is design review, it was important that our annotation tools were  <b style={{fontWeight:500}}>easy to use</b> and <b style={{fontWeight:500}}>encourage collaboration</b>.

          {/* <br/><br/>

          <b>Initial Flow</b> */}

          <br/><br/>

          <h3 className='h3'>Solution</h3>
          
          <b>Added Explanations</b> <div class="tag">Table Stakes</div> <br/>
          <p className='p'>We introduced tooltips and guided hints across all platforms to clarify tool functionality.</p>
          <ul className='ul'>
            <li className='li'>Users now see in-app explanations when they hover over a tool. </li>
            <li className='li'>On XR devices, controller button hints help users understand interactions.</li>
          </ul>
          <br/>

          {/* ----------------------------- TOOL SWITCHING -----------------------------  */}

          

          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={toolbar_scheme} src={toolbar_scheme} />
          </div>

          <br/><br/>

          <b> Annotation List</b> <div class="tag">Table Stakes</div> <div class="tag" style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiator</div><br/>
          <p className='p'>Campfire's annotation system is critical for design review but wasn't easy to track. We introduced:</p>
          <ul className='ul'>
            <li className='li'>An annotation list panel, similar to Google Docs' comment sidebar, for easier tracking. </li>
            <li className='li'>Users can now see all types of annotations in one place, edit, resolve, filter, and export feedback.</li>
            <li className='li'>Read more about this in the <a target="_blank" style={{color: this.accent_color}} href="https://miraram.com/campfire">annotations case study</a>.</li>
          </ul>
          <br/>

          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:0}}>
            <source src={comment_panel} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <br/><br/>

          <b> Unified Toolbar</b> <div class="tag" style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiator</div><br/>
          <p className='p'>We redesigned the toolbar for consistency across all platforms:</p>
          <ul className='ul'>
            <li className='li'>Desktop, tablet, and XR devices now have the same tool layout for a seamless experience. </li>
            <li className='li'>XR devices feature a controller menu for tool switching, improving usability.</li>
          </ul>
          <br/>

          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:35}}>
            <source src={switch_tools} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          
          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:0}}>
            <source src={desktop_tools1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          
          

          <br/><br/>

          <b>Final</b>
          <p className='p'>With these changes, more users started discovering and using collaboration tools, improving engagement in design reviews and technical training.</p>

          {/* [IMAGE PLACEHOLDER]<br/>
          ✅ Short video demonstrating a smooth annotation workflow—from adding a comment to resolving feedback.<br/> */}
          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:0}}>
            <source src={desktop_tools} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          
  

          </p>

          <div className="divider"></div>
          
          {/* ---------------------------------- Objective 3 ----------------------------------  */}
          <h2 className='h2'>Objective 3 <span style={{opacity: '92%', fontWeight: 400}}>: Collaborative tools should be intuitive to users</span></h2>
          <h3 className='h3'>Problem</h3>
          <p className='p'>
          Users quickly understood Campfire during trade show demos, but struggled when exploring the app independently.
          </p>
          <ul className='ul'>
            <li className='li'>Frequent support requests for tutorials and guidance.</li>
            <li className='li'>No onboarding experience at launch.</li>
            <li className='li'>Users needed structured ways to learn features on their own.</li>
          </ul>

  
          <div className="callout">
            <p className='p'>
            <b>User Stories</b><br/>
            <i>As a Technical Training Specialist at [Manufacturing Company]*, I need an onboarding experience that mimics a real demo, so new trainees can learn Campfire's features step by step without requiring live instruction.</i> <br/><br/>

            <i>As an Mechanical Engineer at [Manufacturing Company]*, I need quick, in-app guidance to help me understand key features without searching for external support, so I can start reviewing and iterating on 3D models immediately.</i> <br/><br/>

            <i> As a Structural Engineer at [Aerospace/Defense Company]*, I need on-demand tutorials and tooltips, so I can quickly learn how to navigate the app and collaborate without getting frustrated.</i> <br/><br/>

            <span style={{fontSize:'10px'}}>*Redacted company name</span>
         
            </p>
          </div>

          <p className='p'>

          <h3 className='h3'>Solution</h3>
          
          <b>Sample Projects and Upgraded Project List </b> <div class="tag">Table Stakes</div> <div class="tag" style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiator</div><br/>
          <p className='p'>We added a "Learn" tab to the Project List with:</p>
          <ul className='ul'>
            <li className='li'>Preloaded sample projects to help users explore features. </li>
            <li className='li'>A structured layout ("Projects," "Shared," "Learn") to emphasize collaboration. New users start on the "Learn" tab.</li>
          <li className='li'>Added collaboration-focused features to the Project List.</li>
          </ul>

          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={project_list} src={project_list} />
          </div>
     
          <br/>
      

          <b>Spark Onboarding </b> <div class="tag" style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiator</div><br/>
          <p className='p'>We created Spark Onboarding to simulate a guided trade show experience and mimic the look and feel of a multiuser collaborative session in Campfire:</p>
          <ul className='ul'>
            <li className='li'>An AI user named "Spark" walks user through key tools interactively.  </li>
            <li className='li'>Available on Quest (rolling out to desktop soon).</li>
           
          </ul>

          <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={spark_flow} src={spark_flow} />
          </div>
          <br/><br/>

          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:35}}>
            <source src={spark_slice} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
    
          

          <br/><br/><br/>

          <b>Easy Access to Support Materials </b> <div class="tag">Table Stakes</div><br/>
          <p className='p'>We made help easier to find by adding:</p>
          <ul className='ul'>
            <li className='li'>A help button in the app that links directly to support docs.</li>
            <li className='li'>A Support Center with articles & videos explaining core features.</li>
          </ul>

          <div style={{maxHeight:'600px', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={support_center} src={support_center} />
          </div>
    


          <br/><br/><br/>

          <b>Collaboration-Focused Marketing Materials</b> <div class="tag"style={{backgroundColor:'#1371ff2a',color:'#1372FF'}}>Differentiator</div><br/>
          <p className='p'>Even with an improved onboarding experience, we realized that first impressions matter. Many users weren't aware of Campfire's collaborative capabilities before downloading the app. To set the right expectations and reinforce its core value, we:</p>
          <ul className='ul'>
            <li className='li'>Showcased multiple users in all marketing materials (videos, screenshots, website).</li>
            <li className='li'>Created tutorial-style marketing videos demonstrating real collaboration in action.</li>
          </ul>
    

          <div style={{maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={quest_store} src={quest_store} />
          </div>

          <br/><br/><br/>

          <b>Final</b>
          <p className='p'>With these improvements, users could onboard themselves -- reducing support requests and increasing feature adoption.</p>

          <iframe
            width="100%"
            height="500px"
            minHeight="600px"
            src="https://www.youtube.com/embed/TkJKAb0Y1To?si=pLve2rISdAcdZUQv"
            title="Learn with Spark" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          





          <div className="divider"></div>
          
          {/* ---------------------------------- Final Collaboration Flow ----------------------------------  */}
          <h2 className='h2'>Final Collaboration Flow</h2>
          <p className='p'>With all the improvements to sharing, collaboration tools, and onboarding, the user journey in Campfire became more seamless and intuitive. A new user can now:</p>
          <ol className='ul'>
            <li className='li'>Discover Campfire through the Quest App Store, website, or enterprise onboarding.</li>
            <li className='li'> Download the app and start Spark Onboarding to quickly learn the core tools.</li>
            <li className='li'> Explore sample projects in the "Learn" tab.</li>
            <li className='li'>Create a project and share it instantly.</li>
            <li className='li'> Use collaboration tools (annotations, laser pointer, slice, etc) to interact in real-time.</li>
            <li className='li'> Find help easily through in-app tooltips, the support center, or tutorials.</li>
          </ol><br/>

          <video autoPlay loop muted playsInline width="100%" height="auto" style={{borderRadius:35}}>
            <source src={share_quest} type="video/mp4" />
            Your browser does not support the video tag.
          </video> <br/>

          <p className='p'>By streamlining this flow, more users successfully engaged with Campfire's collaborative features, leading to higher adoption, retention, and a more intuitive onboarding experience.</p>

          <div className="divider"></div>


          {/* ---------------------------------- Imapct ----------------------------------  */}


          <h2 className='h2'>Impact</h2>
          <p className='p'>Our improvements to sharing, collaboration tools, and onboarding had a significant effect on user adoption, engagement, and business growth. By making Campfire's collaboration features more intuitive and accessible, we saw:</p>

          <p className='p'><b>Increase in Active Collaboration</b> <br/></p>
          <ul className='ul'>
            <li className='li'><b style={{color:this.accent_color}}>62% of users engaged in multiuser sessions</b> after simplifying the sharing process (previously at 9%), showing higher adoption of real-time collaboration.</li>
            <li className='li'> <b style={{color:this.accent_color}}>98% increase in users trying collaborative tools</b> after the introduction of Spark Onboarding (from 2% to nearly 100% adoption).</li>
          </ul>

          <p className='p'><b>Stronger User Retention & Satisfaction</b> <br/></p>
          <ul className='ul'>
            <li className='li'> <b style={{color:this.accent_color}}>100% increase in user retention</b>, as new users could onboard themselves more effectively.</li>
            <li className='li'> <b style={{color:this.accent_color}}>Reduction in support requests</b> for onboarding & collaboration tools, thanks to Spark Onboarding and the Help Center.</li>
            <li className='li'> Positive user feedback, with users expressing delight with the app's ease of use</li>
          </ul>

          <p className='p'><b>Business Growth & Enterprise Adoption</b> <br/></p>
          <ul className='ul'>
            <li className='li'>Secured new enterprise customers, including <b style={{color:this.accent_color}}>Whirlpool</b>, <b style={{color:this.accent_color}}>Dyson</b>, and <b style={{color:this.accent_color}}>Coca-Cola</b>, leading to an increase in Enterprise license sales.</li>
          </ul>

          {/* <div className="divider"></div> */}


          {/* ---------------------------------- Key Takeaways ----------------------------------  */}

          {/* <h2 className='h2'>Key Takeaways</h2>

          <b>Data-Driven Design is Essential</b> <br/>
          <p className='p'>While user feedback shapes most of our decisions, app usage data revealed critical UX gaps early on. Identifying and acting on these insights allowed us to iterate effectively and improve engagement. </p>
          
          <b>Accessibility Drives Engagement </b> <br/>
          <p className='p'>Collaboration only happens when it's easy. By simplifying sharing and annotation tools, we saw a significant increase in collaborative interactions.</p>

          <b>Using Familiar User Patterns  </b> <br/>
          <p className='p'>Users expect all collaboration tools to function similarly to 2D platforms like Google Drive, Notion, and Figma. Aligning with familiar UX patterns reduced friction and increased adoption.</p>

          <b>Marketing and UX Should Work Together  </b> <br/>
          <p className='p'>The way a product is presented influences how users perceive and engage with it. By showcasing multiuser collaboration on CAD models in marketing materials, we set the right expectations before users even opened the app—leading to higher adoption from users in our ICP.</p> */}


          

          
  

          </p>
          

   
          



         

          <p></p>
          </PasswordComponent> 
        </div>
        <Footer/>
      </div>
    )
  }
}

export default CampfireCollabProjectPage;
