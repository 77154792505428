import React from "react";
import { isMobile, isSafari } from 'react-device-detect'
import LandingCard from "./LandingCard";
import HeaderBar from '../../Components/HeaderBar';
import './LandingPage2.css';
import LandingPageProjectCard from "./LandingPageProjectCard";

import Flourish from '../../assets/landing_page_2/flourish_thumbnail.png';
import Unmasked from '../../assets/landing_page_2/unmasked_thumbnail.png';
import Campfire from '../../assets/landing_page_2/campfire_thumbnail.png';
import Silvr from '../../assets/landing_page_2/silvr_thumbnail.png';
// You'll need to add a Google thumbnail image later
// import Google from '../../assets/landing_page_2/google_thumbnail.png';
import Footer from "../ProjectDetailPage/ProjectDetailPageComponents/Footer";
import LandingPageBottom from "./LandingPageBottom";
import ScrollButton2 from "../../Components/ScrollButton2/ScrollButton2";
import { checkMobile } from "../../Styles/ScreenCalcs";

export default class LandingPage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: checkMobile()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.setState({ isMobile: checkMobile() });
  }

  render() {
    // if (isSafari && !this.state.isMobile) {
    //   return this.safariRender();
    // }
    if (!this.state.isMobile) {
      return this.normalRender();
    }
    else {
      return this.mobileRender();
    }
  }

  mobileRender() {
    return (
      <div className="landing-page-2">
        <HeaderBar />
        <div className="landing-page-2-container">
          <ScrollButton2 />
          <LandingCard />
          <div id="scroll-target" className="landing-page-2-project-container">
            <LandingPageProjectCard
              img={Campfire}
              title="Campfire"
              subtitle="Collaboration"
              blurb="Refining the collaborative workflow in a 3D design review platform, driving user engagement and enterprise adoption."
              page='/campfire_collab'
            />
            <LandingPageProjectCard
              img={Flourish}
              title="Flourish"
              subtitle="Symptom Tracker"
              blurb="Designing an easy to use symptom, trigger, and treatment tracker for patients with chronic illnesses."
              page='/flourish'
              />
            <LandingPageProjectCard
              img={Unmasked}
              title="Unmasked"
              subtitle="Community Building"
              blurb="The redesign of a mental health-centered app for college students that led to increased usage and press recognition."
              page='/unmasked'
              />
            <LandingPageProjectCard
              img={Silvr}
              title="Meta Quest Research"
              subtitle="VR Social Interaction"
              blurb="Researching avatar movement veracity in VR, advised and funded by a gift from Meta Research. Presented at the 2024 SAI Computing Conference."
              page='/silvr'
              />
          </div>
          <div style={{marginTop: "10vh"}} />
          <LandingPageBottom />
        </div>
        <Footer />
      </div>
    )
  }

  normalRender() {
    return (
      <div className="landing-page-2">
        <div className="landing-page-2-container">
          <ScrollButton2 />
          <HeaderBar />
          <LandingCard />
          <div id="scroll-target" className="landing-page-2-project-container">
          <LandingPageProjectCard
              img={Campfire}
              title="Campfire"
              subtitle="Collaboration"
              blurb="Refining the collaborative workflow in a 3D design review platform, driving user engagement and enterprise adoption."
              page='/campfire_collab'
            />
            <LandingPageProjectCard
              img={Flourish}
              title="Flourish"
              subtitle="Symptom Tracker"
              blurb="Designing an easy to use symptom, trigger, and treatment tracker for patients with chronic illnesses."
              page='/flourish'
              />
            <LandingPageProjectCard
              img={Unmasked}
              title="Unmasked"
              subtitle="Community Building"
              blurb="The redesign of a mental health-centered app for college students that led to increased usage and press recognition."
              page='/unmasked'
              />
            <LandingPageProjectCard
              img={Silvr}
              title="Meta Quest Research"
              subtitle="VR Social Interaction"
              blurb="Researching avatar movement veracity in VR, advised and funded by a gift from Meta Research. Presented at the 2024 SAI Computing Conference."
              page='/silvr'
              />
          </div>
          <div style={{marginTop: "150px"}} />
          <LandingPageBottom />
        </div>
        <Footer />
      </div>
    );
  }

  safariRender() {
    return (
      <div>
      </div>
    );
  }
}