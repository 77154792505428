// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import { Helmet } from 'react-helmet';

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ImageScrollList from './ProjectDetailPageComponents/ImageScrollList';

import ScrollButton from '../../Components/ScrollButton';

// assets
// Import your Figma case study assets here
// Example:
// import banner from '../../assets/figma/figma_banner.png';
import floorDwellingMira from '../../assets/landing_page_2/floor_dwelling_mira.png';

// Add imports for case study images/videos
import campfireSample from '../../assets/coverletters/campfire_sample.png'; 
import netxdSample from '../../assets/coverletters/netxd_sample.png'; 
import flourishSample from '../../assets/coverletters/flourish_sample.png'; 
import unmaskedSample from '../../assets/coverletters/unmasked_sample.png'; 
import share_quest from '../../assets/campfire_collab/share_quest.mp4';
import figma_drawings from '../../assets/coverletters/figma_drawings.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css';
import '../../Styles/coverletter.scss';
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';

class FigmaDevCoverLetterPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    // Replace with your Figma banner when available
    // this.background = banner;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#ed8f8a' // Pink from About page links
    
    // Adding custom styles for the video container
    this.videoStyle = {
      width: '100%',
      height: '100%', // Fixed height to match other containers
      minHeight: '400px',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: '8px'
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div key={index} className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        {/* <div className={'project-detail-page-banner'} style={{backgroundColor: '#F1F1F1', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} /> */}
        <br/><br/><br/><br/><br/>
        <div className='project-detail-page-layout'>
          {/* ---------- Title ---------- */}
          {/* ---------- Password ---------- */}
          
          <PasswordComponent
            sha256={'444b8898ddff6ce3e121c17b3053b33e1f7658fa1d7ba5f2bcb25c61c2a235c6'}
            color={'#39271C'}>   
          
          <h1 className="h1">Figma Cover Letter</h1>
          <h2 className="h2 subtitle">
            Why I'd love to shape the future of Figma's Developer Tools as a Product Designer
          </h2>
          <br/>

          {/* ---------- About ---------- */}
          <h2 className='h2'>About</h2>
          <p className='p'>
            Thanks for stopping by! I'm Mira, a NYC-based UX designer with <b className="text-emphasis">7+ years of experience</b> designing <b className="text-emphasis">0-to-1 solutions</b> for clients ranging from <b className="text-emphasis">Y Combinator-backed startups to Meta</b>. My work has been featured at <b className="text-emphasis">Sundance Film Festival, ABC News, and PBS</b>. I currently lead the design of enterprise 3D collaboration software at <a href="https://campfire3d.com/" target="_blank" rel="noopener noreferrer" style={{color: this.accent_color, fontWeight: 600}}>Campfire</a>, where I create <b className="text-emphasis">multi-platform, research-driven solutions</b> that balance innovation with usability. I specialize in <b className="text-emphasis">business-focused design strategy</b> rooted in thorough research and brought to life with engaging visual interfaces.
          </p> <br/>
          <div className="center-content">
            <img 
              src={floorDwellingMira} 
              alt="Mira illustration" 
              className="profile-image"
            />
          </div>
          <br/>

          {/* ---------- Why Figma? ---------- */}
          <div className="callout-section" style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }}>
            <div className="callout-container" style={{ background: 'transparent', boxShadow: 'none', padding: '0', marginLeft: 0 }}>
              <div className="callout-text left-aligned" style={{ marginLeft: 0, paddingLeft: 0, flex: '1' }}>
                <h2 className='h2'>Why Figma?</h2>
                <p className='p'>
                  <b className="text-heading">I literally live in Figma</b><br/>
                  I've been a <b className="text-emphasis">power user of Figma since 2017</b>, and I really can't imagine designing in anything else. Beyond traditional UX workflows, I use Figma for all sorts of unconventional things: trip planning, book layout, vector animation (like the ones on my home page), and even drawing anime-style vector art. <b className="text-emphasis">Figma is both my workspace and creative playground.</b>
                </p><br/>
                
                <p className='p'>
                  What excites me most about Figma is how it has revolutionized the way teams collaborate. Through my experience designing enterprise collaboration tools, I've seen firsthand how the right features can transform team dynamics and workflows. I'm particularly drawn to Figma's focus on <b className="text-emphasis">making design accessible and enjoyable for everyone</b>, regardless of their role or technical background.
                </p><br/>
                
                <p className='p'>
                  <b className="text-heading">Why Developer Tools?</b><br/>
                  I'd love the opportunity to help shape Dev Mode. <b className="text-emphasis">Part of what makes Figma special is its ability to bridge the gap between design and development</b>. Through my work at Campfire, I've pioneered new interaction patterns for 3D design review and training across diverse stakeholders, many with no prior 3D experience. As a designer with a developer background, I also understand the conflicting workflows and needs of both disciplines. <b className="text-emphasis">Creating simple but robust tools that work for multiple stakeholders is a challenge I know well, and one I'd love to keep solving at Figma.</b>
                </p>
              </div>
              <div className="callout-image right-aligned" style={{ flex: '1' }}>
                <div className="image-container" style={{ 
                  maxWidth: "600px", 
                  marginLeft: "20px",
                  marginTop:"60px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}>
                  <img 
                    src={figma_drawings} 
                    alt="Figma design drawings" 
                    style={{ 
                      borderRadius: '25px', 
                      width: '100%', 
                      height: 'auto', 
                      objectFit: 'contain'
                    }}
                  />
                  {/* <figcaption style={{ fontSize: "0.8em", color: "#888", textAlign: "center", marginTop: "8px" }}>
                    Yup, it's all Figma
                  </figcaption> */}
                </div>
              </div>
            </div>
          </div>
          <br/>

          {/* ---------- Why I'm a Strong Fit ---------- */}
          <h2 className='h2'>Why I'm a strong fit</h2> 
          
          {/* Hybrid Design & Development Background - Section 1 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image left-aligned">
                <div className="image-container">
                  <video 
                    src={share_quest}
                    alt="Campfire share quest functionality" 
                    controls
                    autoPlay
                    muted
                    loop
                    className="video-element"
                    style={this.videoStyle}
                  ></video>
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>01.</span> Hybrid design & development background
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Work closely with engineers and contribute directly to front-end development in <b className="text-emphasis">React and Unity</b>. Built functional Unity prototype that secured <b className="text-emphasis">Meta funding</b>. 
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Designed and developed virtual environment for a Meta Research study in Unity, creating <b className="text-emphasis">novel interaction patterns</b> that led to the study's publication in the Springer series "Lecture Notes in Networks and Systems".
                      <div className="case-study-button">
                        <a href="/silvr" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-blue">
                            View Meta Quest Research
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      I also have an <b className="text-emphasis">M.S. in Computer Science from Dartmouth</b>, and built this portfolio site from scratch with <b className="text-emphasis">React </b>!
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> As someone who's both designed and developed software, I understand the unique challenges of bridging design and development workflows. This hybrid background would help me contribute to Figma's mission of closing the gaps between design and production, particularly in Dev Mode.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* Product Design & Strategic Development - Section 2 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>02.</span> Product design & strategic development
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Led the redesign of Campfire's collaboration tools, creating intuitive interfaces that drove a <b className="text-emphasis">98% increase in collaborative tool usage</b> and helped secure contracts with <b className="text-emphasis">Fortune 500 companies including Coca-Cola, PepsiCo, and Whirlpool</b>.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Spearheaded Unmasked's redesign as Head of Product, managing a cross-disciplinary team that grew the platform to <b className="text-emphasis">10K+ users across 45+ universities</b>, with features in <b className="text-emphasis">ABC News and the Washington Post</b>.
                      <div className="case-study-button">
                        <a href="/unmasked" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-purple">
                            View Unmasked
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> My experience delivering high-quality products and driving user adoption aligns with Figma's mission to make design accessible to everyone. I can help craft clear storytelling around customer needs and business opportunities for Dev Mode, while ensuring features are both powerful and intuitive.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image right-aligned">
                <div className="image-container">
                  <img 
                    src={unmaskedSample} 
                    alt="Unmasked application interface" 
                  />
                </div>
              </div>
            </div>
          </div>

          {/* AI & Innovation Experience - Section 3 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-image left-aligned">
                <div className="image-container">
                  <img 
                    src={netxdSample} 
                    alt="NetXD banking platform interface" 
                  />
                </div>
              </div>
              <div className="callout-text right-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>03.</span> AI & innovation experience
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Created Campfire's "Spark" onboarding experience, an innovative AI-guided tutorial that drove <b className="text-emphasis">62% of users to engage in multiuser sessions</b>, up from just 9% previously.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Currently leading the design for an upcoming <b className="text-emphasis">agentic AI tool </b> for NETXD for automating complex banking workflows.
                      <div className="case-study-button">
                        <div className="button-content button-gray">
                          Coming Soon
                        </div>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> My experience designing novel experiences for AI tools has shown me how technology can transform workflows. I'd love to use AI to make Figma's Developer Tools even more powerful and accessible, particularly in exploring how AI can accelerate the design → production process.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          {/* User-Centered Innovation - Section 4 */}
          <div className="callout-section">
            <div className="callout-container">
              <div className="callout-text left-aligned">
                <h3 className="h3 section-title">
                  <span style={{color: this.accent_color}}>04.</span> User-centered innovation
                </h3>
                <p className='p'>
                  <ul className='ul'>
                    <li className='li'>
                      Published research on XR social interaction with <b className="text-emphasis">Meta Research and Dartmouth</b>, developing methodologies for evaluating user experience in collaborative environments.
                      <div className="case-study-button">
                        <a href="/silvr" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-blue">
                            View Meta Quest Research
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      Analyzed usage analytics and user feedback to identify usability issues in Campfire's tools, implementing improvements that increased collaborative tool usage by <b className="text-emphasis">98%</b> and drove a <b className="text-emphasis">100% increase in user retention</b>.
                      <div className="case-study-button">
                        <a href="/campfire_collab" target="_blank" rel="noopener noreferrer">
                          <div className="button-content button-red">
                            View Campfire
                          </div>
                        </a>
                      </div>
                    </li>
                    <li className='li'>
                      <b className="text-emphasis">Figma Alignment:</b> My research background and focus on data-driven improvements has taught me how to balance powerful features with simplicity. This experience would be valuable in helping Figma enhance its tooling for the developer audience, making it easier for teams to understand, adopt, and integrate with existing development workflows.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="callout-image right-aligned">
                <div className="image-container">
                  <img 
                    src={campfireSample} 
                    alt="Campfire collaboration platform" 
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* ---------- Summary ---------- */}
          <br/>
          <h2 className='h2'>Summary</h2>
          <p className='p'>
            Dev Mode represents everything I love about Figma's approach to bridging design and development - taking complex workflows and making them accessible to everyone. Through my experience designing enterprise collaboration tools, I've come to appreciate the challenge of creating simple yet powerful features that work for diverse teams. I'm excited about the opportunity to bring this perspective to Figma's Developer Tools, helping shape features that streamline designer-developer handoffs and make technical collaboration more efficient and enjoyable.
          </p>
          
          <p className='p'>
          Thank you for your time and consideration. I look forward to hearing back!
          </p>
          
          {/* ---------- Contact Info ---------- */}
          <div className="contact-section">
            <div className="contact-container">
              <div className="contact-links">
                <a href="https://www.linkedin.com/in/miraram/" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>LinkedIn</a>
                <span className="separator">|</span>
                <a href="https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>Resume</a>
                <span className="separator">|</span>
                <a href="mailto:mira.ram@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: this.accent_color }}>mira.ram@gmail.com</a>
                <span className="separator">|</span>
                <span className="phone">(785) 304-3812</span>
              </div>
            </div>
          </div>
          
          </PasswordComponent>
          
        </div>
        <Footer />
      </div>
    );
  }
}

export default FigmaDevCoverLetterPage; 