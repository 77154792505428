import React from "react";
import { isMobile, isSafari } from 'react-device-detect'

import '../../Styles/Fonts.scss';
import './LandingPageBottom.css';
import FloorDwellingMira from "../../assets/landing_page_2/floor_dwelling_mira.png";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import TranslateButton from "../../Components/TranslateButton/TranslateButton";
import { checkMobile } from "../../Styles/ScreenCalcs";

export default class LandingPageBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: checkMobile(),
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateLayout);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayout);
    }

    updateLayout = () => {
        this.setState({ isMobile: checkMobile() });
    }

    render() {
        return (
            <div className="landing-page-bottom ">
                <img src={FloorDwellingMira} className="landing-page-bottom-image" />
                <div className='font-large everything-else'>Everything else</div>
                <p className='font-medium'> But wait, there’s more! Explore my other projects - from fully developed XR experiences to illustrated animations. </p>
                <div className="landing-page-bottom-button font-medium">
                    <TranslateButton href='/work'
                        left_icon={ArrowRight}
                        middle_text="&nbsp;&nbsp; Check out my work &nbsp;&nbsp;"
                        right_icon={ArrowRight}
                        color="light-pink"
                    />
                </div>
            </div>
        );
    }
}